import { Component, OnInit, Input, Output, EventEmitter, NgZone, OnChanges, ChangeDetectorRef, ViewChild, ElementRef, OnDestroy, HostListener, AfterViewInit, AfterViewChecked, AfterContentChecked } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { LayoutService, RequestService, LayoutUtilsService, TokBoxService, PageScopeService, RoomSessionService, FeaturesPermissionService } from '../../../shared/services';
import { ModalUserViewDialogComponent } from '../custom-user-view-dialog/custom-user-view-dialog.component';
import { ModalSchedulerDialogComponent } from '../custom-scheduler-dialog/custom-scheduler-dialog.component';

import { BdcWalkService } from '../../modules/bdc-walkthrough';
import { CustomSelectCreateAutocompleteComponent } from '../custom-select-create-autocomplete/custom-select-create-autocomplete.component';
import { DefaultSettings } from '../layout-components';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalIframeDialogComponent } from '../iframe-dialog/iframe-dialog.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AblyService } from '../../services/ably.service';
import { ConfirmEntityDialogComponent } from '../modals/confirm-entity-dialog/confirm-entity-dialog.component';
import { ChatService } from '../../services/chat.service';
import { EventType } from '../../services/enums/eventType';
import { PageType } from '../../services/enums/pageType';
import { EventTrack } from '../../services/models/event-tracking.model';
import { ClickType } from '../../services/enums/clickType';
import { CustomDynamicDialogComponent } from '../modals/custom-dynamic-dialog/custom-dynamic-dialog.component';
import { CustomSchedulerComponent } from '../custom-scheduler/custom-scheduler.component';
// import { RecorderService } from '../../services/recorder.service';

declare var RecordRTC_Extension;

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit, OnDestroy, AfterContentChecked {
  private subscriptions: Subscription[] = [];
  public selectedUser: any;
  public organization = undefined;
  public isAdmin: boolean = false;
  public isOrgOwner: boolean = false;
  public is10hSubscription: boolean = false;
  public orgType = environment.orgType;
  public lockSite = environment.lockSite;
  public paymentGateway = environment.paymentGateway;
  public hasListingDirectory = environment.hasListingDirectory;
  public permission: any[] = [environment.customKeys.roleEdit, environment.customKeys.roleAdmin];
  public viewPermission: any[] = [environment.customKeys.roleView];
  public enableTranslation = environment.enableTranslation;
  public s3BucketUrl = environment.s3BucketUrl;
  public topLeftDefaultImage = "assets/vertical/" + this.orgType + "/images/top-logo-main.png";
  public imageInputTopLeftImage: string = this.s3BucketUrl + this.orgType + "/" + this.requestService.orgId + "/top-logo-min.png";
  public title = environment.serverName;
  public subTitle = environment.subServerName;
  public production = environment.production;
  public donateLink: string = undefined;
  public pushRightClass: string;
  public rightPushLeftClass: string;
  public userType: string = 'default';
  public language = 'en';
  public showMobileMenu: boolean = true;
  public loadingSessions: boolean = false;
  public loadingRoomsList: boolean = false;
  public tabSelected: string = undefined;
  public dateFormat: string = 'dd-MM-yyyy';
  public sessionsFaveLive: any[] = [];
  public roomsList: any[] = [];
  public settingOrgObject = undefined;
  public subscriptionOrgObject = undefined;
  public subscriptionUpgradeStatus = DefaultSettings.SubscriptionUpgradeMessage;
  public subscriptionExhibitStatus = DefaultSettings.SubscriptionExhibitMessage;
  public subscriptionExhibitUpgradeStatus = DefaultSettings.SubscriptionExhibitUpgradeMessage;
  public canNewList: any[] = DefaultSettings.roomNewList;
  public availableLanguages: any = [];
  isDnD: boolean = false;
  isBusy: boolean = false;
  iframeDialog: MatDialogRef<any, any>;
  highlightDirectory: boolean = false;
  // _isOpenGlobalChat: boolean = false;
  // _isOpenAgenda: boolean = false;
  // lastChatMessage: any = { message: '' };
  // videoChatInvite: boolean = false;
  newAppVersion: boolean = false;
  goodConnection: boolean = true;
  isMobile: boolean = false;
  isTablet: boolean = false;
  isTopOpen: boolean = true;
  recordingStatus: boolean = false;
  // counterSubscription: Subscription;
  // countDownTextDay: number = undefined;
  // countDownTextHour: number = undefined;
  // countDownTextMin: number = undefined;
  // countDownTextSec: number = undefined;
  // hideHintFirstTimeEntry: any = undefined;
  // pageLoaded: boolean = false;
  isUserRole: string = undefined;
  isSuperAdmin: boolean = false;
  // isProfileShowing: boolean = false;
  isMyAgendaShowing: boolean = false;
  isRoomListShowing: boolean = false;
  public disableEventBlueprints: boolean = false;

  // @Input() highlightNotifications: boolean = false;
  @Input() showProductionLabel: any = undefined;
  @Input() showExit: boolean = false;
  @Input() roomData: any = undefined;
  @Input() sessionData: any = undefined;
  // @Input() pushedTileLink: string = undefined;
  @Input() showStartEndSession: boolean = false;
  @Input() viewAsAttendee: boolean = false;
  @Input() backLayer: boolean = false;
  @Input() activeMode: boolean = false;
  @Input() currentSessionUserRole: any = undefined;
  @Input() breakoutData: any = undefined;
  @Input() breakoutId: any = undefined;
  // @Input()
  // set breakoutTimer(timerObj: any) {
  //   if (timerObj) {
  //     this.countdownBreakout(timerObj.date, timerObj.update);
  //   }
  // }
  @Input() isShowSettings: boolean = false;
  @Input() advancedView: boolean = false;
  @Input() showTemplateActions: boolean = false;

  public viewPortModesKeys: any = DefaultSettings.viewPortModesKeys;
  public viewPortModes: any = DefaultSettings.viewPortModes;

  public desktopNames = DefaultSettings.desktopNames;
  @Input() viewPortMode: string = 'desktop';
  @Input() viewMode: string = 'desktop';
  @Input() streamMode: string = 'rtc';
  @Input() templateObject: any = undefined;
  @Input() layoutSessionData: any = { _id: undefined, name: undefined };


  public roomDataType: any = 'room';
  public sessionDataType: any = 'session';
  public _roomId: any = undefined;
  @Input()
  set roomId(roomId: any) {
    let oldRoomId = undefined;
    if (this._roomId) {
      oldRoomId = JSON.parse(JSON.stringify(this._roomId));
    }
    this._roomId = roomId;
    if (roomId !== oldRoomId) {
      this.sessionfilterData = undefined;
    }
    if (roomId) {
      setTimeout(() => {
        this.sessionfilterData = {
          '$and': [
            { 'organizationId._id': { '$eq': this.requestService.orgId } },
            { 'room._id': { '$eq': this.roomId } }
          ]
        };
      }, 200);
    }
  }
  get roomId() {
    return this._roomId;
  }
  public sessionfilterData: any;
  public roomfilterData: any;
  @Output() setRoomReturn = new EventEmitter<any>();
  @Output() setSessionReturn = new EventEmitter<any>();


  // @Output() highlightNotificationsChange = new EventEmitter<boolean>();
  @Output() onendSession = new EventEmitter<any>();
  @Output() viewModeReturn = new EventEmitter<string>();
  @Output() templateActionReturn = new EventEmitter<string>();
  @Output() backLayerReturn = new EventEmitter<boolean>();
  @Output() viewPortLayerReturn = new EventEmitter<boolean>();

  @ViewChild('roomList') roomList: CustomSelectCreateAutocompleteComponent;
  @ViewChild('sessionList') sessionList: CustomSelectCreateAutocompleteComponent;
  @ViewChild('dialogSchedule') dialogSchedule: CustomDynamicDialogComponent;
  @ViewChild('dialogCalendar') dialogCalendar: CustomDynamicDialogComponent;
  @ViewChild('customCalendar') customCalendar: CustomSchedulerComponent;
  constructor(private bdcWalkService: BdcWalkService,
    private layoutUtilsService: LayoutUtilsService, private requestService: RequestService, public router: Router, private translate: TranslateService, private layoutService: LayoutService,
    public dialog: MatDialog, private zone: NgZone, public opentokService: TokBoxService, private route: ActivatedRoute, public changeDetectorRef: ChangeDetectorRef, private deviceService: DeviceDetectorService, public pageScope: PageScopeService, private urlRouter: Router, private roomSessionService: RoomSessionService, public ablyService: AblyService, public chatService: ChatService, private featuresPermissionService: FeaturesPermissionService) {
    // this.router.events.subscribe(val => {
    //     if (val instanceof NavigationEnd && window.innerWidth <= 992 && this.isToggled()) {
    //         this.toggleSidebar();
    //     }
    // });
    this.isTablet = this.deviceService.isTablet();
    if (this.deviceService.isMobile()) {
      this.isMobile = this.deviceService.isMobile();
      this.isTopOpen = false;
    }
    this.userType = this.requestService.getUserType();
  }

  ngOnInit() {
    this.pushRightClass = 'push-right';
    this.rightPushLeftClass = 'right-push-left';
    // console.log(this.route.snapshot)
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.isAdmin = this.requestService.isUserRoleAdmin();
          this.isSuperAdmin = this.requestService.isUserRoleSuperAdmin();
          this.isUserRole = this.requestService.getUserRole();
          this.roomfilterData = {
            '$and': [
              { 'organizationId._id': { '$eq': this.requestService.orgId } }
            ]
          };
          this.selectedUser = data;
          if (this.selectedUser.hasOwnProperty('chat_dnd')) {
            if (!localStorage.getItem('temp-availability-status')) {
              localStorage.setItem('temp-availability-status', this.selectedUser['chat_dnd'] + '');
              this.isDnD = this.selectedUser['chat_dnd'];
            }
            else
              this.isDnD = localStorage.getItem('temp-availability-status') === 'true' ? true : false;
            // if (this.isDnD + '' != localStorage.getItem('temp-availability-status')) {
            //   this.toggleDnD(false);
            // }
          }
          if (this.selectedUser.hasOwnProperty('listedInTheDirectory') && this.selectedUser.listedInTheDirectory === 'no') {
            this.chatService.isTextChat.next(false);
            this.chatService.isVideoChat.next(false);
          }
        }
        this.language = this.requestService.lang;
      })
    );
    this.subscriptions.push(
      this.requestService.pageOrganization.subscribe((data) => {
        if (data) {
          this.donateLink = data.donate;
          this.organization = data;
          // if (data.hasOwnProperty('settings')) {
          //   // console.log(data.settings);
          //   this.settingOrgObject = data.settings;
          // }
        }
      })
    );
    this.subscriptions.push(
      this.featuresPermissionService.currentSettingsSubject.subscribe((currentSettings) => {
        if (currentSettings) {
          this.settingOrgObject = currentSettings;
          if (currentSettings.web && currentSettings.web.global) {
            if (currentSettings.web.global.hasOwnProperty('disableEventBlueprints')) {
              this.disableEventBlueprints = currentSettings.web.global.disableEventBlueprints;
            }
            if (currentSettings.web.global.hasOwnProperty('dateFormat')) {
              this.dateFormat = currentSettings.web.global.dateFormat;
            }
          }
        }
      })
    );
    this.subscriptions.push(
      this.featuresPermissionService.currentSubscriptionSubject.subscribe((data) => {
        if (data) {
          this.subscriptionOrgObject = data;
          let currentUser = this.requestService.currentUserSubject.getValue();
          if (this.subscriptionOrgObject.email && this.subscriptionOrgObject.email === currentUser.email) {
            this.isOrgOwner = true;
          }
          if (this.isSuperAdmin || this.isAdmin || this.isOrgOwner) {
            if (this.subscriptionOrgObject && this.subscriptionOrgObject.paid && this.subscriptionOrgObject.stat_viewer_hrs > 0 && this.subscriptionOrgObject.stat_rem_viwer_hrs <= 10) {
              this.is10hSubscription = true;
            }
          }
          if (!this.subscriptionOrgObject.features.includes('exhibit-booths')) {
            this.canNewList[1].disabled = true;
          } else {
            if (this.subscriptionOrgObject.package === 'full' || this.subscriptionOrgObject.package === 'professional') {
              if (this.subscriptionOrgObject.total_subscription_exhibit_rooms > 0) {
                if (this.subscriptionOrgObject.number_of_remaining_exhibit_rooms <= 0) {
                  this.canNewList[1].disabled = true;
                  this.canNewList[1].disabledLabel = this.subscriptionExhibitUpgradeStatus;
                }
              } else {
                this.canNewList[1].disabled = true;
                this.canNewList[1].disabledLabel = this.subscriptionExhibitStatus;
              }
            }
          }
        }
      })
    );
    this.subscriptions.push(
      this.requestService.newAppVersion.subscribe((data) => {
        if (data !== undefined) {
          this.newAppVersion = data;
        }
      })
    );
    this.subscriptions.push(
      this.opentokService.isInSession.subscribe((data) => {
        if (data !== undefined) {
          if (data) {
            this.isBusy = data;
            let userObj = {};
            Object.assign(userObj, this.selectedUser);
            userObj['chat_dnd'] = this.isBusy;
            Object.assign(this.selectedUser, userObj);
            this.ablyService.sendUpdateToUsersInMyChannel(userObj);
          }
          else {
            this.selectedUser.chat_dnd = localStorage.getItem('temp-availability-status') === 'true' ? true : false;
            this.ablyService.sendUpdateToUsersInMyChannel(this.selectedUser);
          }
        }
      })
    );

    this.subscriptions.push(
      this.layoutService.appLayoutSubject.subscribe((data) => {
        if (data) {
          this.handleActions(data)
        }
      })
    );
    // setTimeout(() => { this.drawerState = 'out' }, 1000);
    // setTimeout(() => { this.drawerState = 'in' }, 5000);

    this.subscriptions.push(this.chatService.unreadChats.subscribe((data) => {
      if (data !== undefined) {
        let findUnread = false;
        //console.log('keys', Object.keys(this.opentokService.userFlags));
        Object.keys(this.chatService.userFlags).forEach(key => {
          //console.log(key, this.opentokService.userFlags[key])
          if (this.chatService.userFlags[key].hasOwnProperty('unread') && this.chatService.userFlags[key].unread == true)
            findUnread = true;
        });

        if (findUnread) {
          this.highlightDirectory = true;
        }
        else
          this.highlightDirectory = false;

        this.detectChanges();
      }
    }));

    // console.log(this.route.snapshot['_routerState'].url);

    // this.subscriptions.push(this.opentokService._isOpenAgenda.subscribe((value) => {
    //   if (value != undefined) {
    //     this._isOpenAgenda = value;
    //   }
    // }));

    // this.subscriptions.push(this.opentokService.connectionHealth.subscribe((value) => {
    //   if (value != undefined) {
    //     this.goodConnection = value;
    //   }
    // }));

    this.subscriptions.push(this.chatService.dndChat.subscribe((value) => {
      if (value != undefined) {
        this.isDnD = value;
        this.ablyService.sendUpdateToUsersInMyChannel(this.selectedUser);
        // this.ablyService.publish('dnd', {
        //   senderId: this.selectedUser._id,
        //   value: this.isDnD
        // });
        // this.opentokService.sendSignal('dnd', this.isDnD + '', this.opentokService.globalSession);
        this.detectChanges();
      }
    }));

    this.subscriptions.push(this.chatService.isTextChat.subscribe((value) => {
      if (value != undefined) {
        this.ablyService.sendUpdateToUsersInMyChannel(this.selectedUser);
        // this.ablyService.publish('showTextChat', {
        //   senderId: this.selectedUser._id,
        //   value: value
        // });
        // this.opentokService.sendSignal('showTextChat', value + '', this.opentokService.globalSession);
      }
    }));

    this.subscriptions.push(this.chatService.isVideoChat.subscribe((value) => {
      if (value != undefined) {
        this.ablyService.sendUpdateToUsersInMyChannel(this.selectedUser);
        // this.ablyService.publish('showVideoChat', {
        //   senderId: this.selectedUser._id,
        //   value: value
        // });
        // this.opentokService.sendSignal('showVideoChat', value + '', this.opentokService.globalSession);
      }
    }));

    this.subscriptions.push(this.chatService.toggleCommunicate.subscribe(value => {
      if (value)
        this.openGlobalChat();
    }));

    // this.urlRouter.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     // this.opentokService.isOpenGlobalChat.next(false);
    //     document.querySelectorAll('.ng-tooltip').forEach(element => {
    //       document.body.removeChild(element);
    //     });
    //   }
    //   // if (value && !this.hideHintFirstTimeEntry) {
    //   //   this.hideHintFirstTimeEntry = true;
    //   //   document.querySelectorAll('.ng-tooltip').forEach(element => {
    //   //     document.body.removeChild(element);
    //   //   });
    //   // }
    // });

    // this.hideHintFirstTimeEntry = localStorage.getItem('stellar-hideHints');
    // if (!this.hideHintFirstTimeEntry) {
    //   localStorage.setItem('stellar-hideHints', 'true');
    // }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(el => {
      if (el) {
        el.unsubscribe();
      }
    }
    );
    // this.subscriptions = [];
    // this.drawerState = "in";
    // this.isDnD = undefined;
    // this.highlightDirectory = undefined;
    // this._isOpenGlobalChat = false;
    // this._isOpenAgenda = false;
    // this.lastChatMessage = { message: '' };
    // this.videoChatInvite = false;
    // this.ringtoneAudio = undefined;
    // this.ringTimeOut = undefined;
  }

  ngAfterContentChecked() {
    // if (!this.hideHintFirstTimeEntry)
    //   setTimeout(() => this.pageLoaded = true, 3000);
  }

  resetTutorial(): void {
    this.bdcWalkService.reset('session');
  }
  handleActions(action: any): void {
    if (action.name === 'updateroom') {
      if (this.roomList) {
        this.roomList.dataText = action.value.name;
        this.roomList.loadData();
      }
    }
  }
  setLanguage(lang) {
    this.translate.setDefaultLang(lang);
    this.translate.use(lang)
    this.requestService.lang = lang;
    this.language = lang;
    localStorage.setItem('lang', JSON.stringify(lang));
  }
  selectRoom(data) {
    if (data !== this.roomId) {
      this.activeMode = false;
      this.setRoomReturn.emit(data);
    }
  }
  selectSession(data) {
    if (data) {
      if (data !== this.layoutSessionData._id) {
        this.setSessionReturn.emit(data);
      }
    } else {
      this.setSessionReturn.emit(undefined);
    }
  }
  setViewModeReturn(e, mode: string = 'desktop'): void {
    // this.viewMode = mode;
    this.viewModeReturn.emit(mode);
  }
  setBackLayer(val): void {
    this.backLayerReturn.emit(val);
  }
  setViewPort(val): void {
    this.viewPortLayerReturn.emit(val);
  }
  resetSessionSelect(val): void {
    if (this.sessionList) {
      this.sessionList.reset(val);
    }
  }
  resetRoomSelect(val): void {
    if (this.roomList) {
      this.roomList.reset(val);
    }
  }
  setViewModeOffline(status): void {
    if (status) {
      // if(['rtc', 'hls', 'rtctohls', 'meeting', 'largemeeting'].includes(this.streamMode)){
      if (['desktop-offline', 'desktop', 'desktop-2', 'desktop-3', 'desktop-4', 'desktop-hls'].includes(this.viewMode)) {
        this.viewModeReturn.emit('desktop-offline');
        // } else if (['mobile-offline', 'mobile', 'mobile-hls'].includes(this.viewMode)) {
        //   this.viewModeReturn.emit('mobile-offline');
      }
    } else {
      if (['rtc', 'rtctohls', 'meeting', 'largemeeting'].includes(this.streamMode) && ['desktop-offline', 'desktop', 'desktop-2', 'desktop-3', 'desktop-4', 'desktop-hls'].includes(this.viewMode)) {
        this.viewModeReturn.emit('desktop');
        // } else if (['rtc', 'rtctohls', 'meeting', 'largemeeting'].includes(this.streamMode) && ['mobile-offline', 'mobile', 'mobile-hls'].includes(this.viewMode)) {
        //   this.viewModeReturn.emit('mobile');
      } else if (this.streamMode === 'hls' && ['desktop-offline', 'desktop-2', 'desktop-3', 'desktop-4', 'desktop', 'desktop-hls'].includes(this.viewMode)) {
        this.viewModeReturn.emit('desktop-hls');
        // } else if (this.streamMode === 'hls' && ['mobile-offline', 'mobile', 'mobile-hls'].includes(this.viewMode)) {
        //   this.viewModeReturn.emit('mobile-hls');
      } else {
        if (['desktop-offline', 'desktop-2', 'desktop-3', 'desktop-4', 'desktop', 'desktop-hls'].includes(this.viewMode)) {
          this.viewModeReturn.emit('desktop');
          // } else if (['mobile-offline', 'mobile', 'mobile-hls'].includes(this.viewMode)) {
          //   this.viewModeReturn.emit('mobile');
        }
      }
    }
  }
  setTemplateActionReturn(action): void {
    this.templateActionReturn.emit(action);
  }
  isToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
    dom.classList.remove(this.rightPushLeftClass);
  }
  isRightToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.rightPushLeftClass);
  }

  toggleRightSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.rightPushLeftClass);
    dom.classList.remove(this.pushRightClass);
  }

  goHome() {
    this.router.navigate(['/rooms/list']);
  }

  public goToHelpDesk() {
    let filters = {
      "$and": [
        { 'organizationId._id': { '$eq': this.requestService.orgId } },
        { "helpdesk": { "$eq": true } }
      ]
    };
    this.requestService.getDataList('room', {
      fieldKeys: ['_id', 'name', 'owner', 'maparea', 'bgcolor', 'pictureLink', 'opentoall', 'main', 'settings', 'helpdesk', 'showbg', 'emptyRoom'], orderDir: 'asc', orderBy: 'name', filter: filters
    }, (data, error) => {
      if (error) {
        this.layoutUtilsService.showNotification('We\'re sorry, Help Desk is not available at this time.', 'Dismiss');
      }
      if (data) {
        let results = data.results;
        if (results.length > 0) {
          let selectedHelpDesk = data.results[0];
          localStorage.setItem('helpdesk', JSON.stringify(selectedHelpDesk._id));
          this.viewData(selectedHelpDesk, true, false);
          return;
        } else {
          localStorage.removeItem('helpdesk');
          this.layoutUtilsService.showNotification('We\'re sorry, Help Desk is not available at this time.', 'Dismiss');
        }
      }
    });
  }
  viewData(roomData, breakIt = true, preview: boolean = false) {
    this.roomSessionService.openRoom(roomData, (sessionsList) => {
      if (sessionsList) {
        // do nothing
      }
    }, breakIt, preview);
  }
  onLoggedout() {
    // this.logOutApiSendSignal();
    this.requestService.logOutApi('User Clicked logout', true);
  }
  public logOutApiSendSignal() {
    this.ablyService.getStatus((status) => {
      if (status === 'connected') {
        let uid = JSON.parse(localStorage.getItem('uid'));
        this.ablyService.quickPublishToPrivateChannel(this.selectedUser._id, 'logoutUserSession', { uid: uid }, () => {
          console.log('Sent singal i\'m logging');
        });
      }
    });
  }
  changeLang(language: string) {
    this.translate.use(language);
  }
  // public viewProfile(){
  //   const dialogRef = this.dialog.open(ModalUserDialogComponent, {
  //       width: '1200px',
  //       data: {
  //         title: 'My Profile',
  //         data: this.selectedUser,
  //       }
  //     });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       console.log('result', result);
  //     }
  //   });
  // }
  viewProfile() {
    // this.isProfileShowing = true;
    const dialogRef = this.dialog.open(ModalUserViewDialogComponent, {
      width: '800px',
      data: {
        dataType: 'resource/user',
        title: this.translate.instant('My Profile'),
        profile: true,
        data: { _id: this.selectedUser._id },
        modalSetting: undefined
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.isProfileShowing = false;
      if (result) {
        //console.log('result', result);
      }
    });
  }
  viewCalendar(page = false) {
    if (page) {
      this.router.navigateByUrl('/loading', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/events-calendar'])
      );
    } else {
      this.dialogCalendar.open();
      this.customCalendar.loadDBData();
      if (!this.disableEventBlueprints)
        this.customCalendar.blueprintList.loadData();
    }
  }
  viewCalendar2() {
    // this.isProfileShowing = true;
    const dialogRef = this.dialog.open(ModalSchedulerDialogComponent, {
      width: '1600px',
      data: {
        dataType: 'resource/user',
        title: this.translate.instant('Calendar'),
        data: {}
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log('result', result);
      }
    });
  }
  endSession() {
    this.onendSession.emit(true);
  }
  startSession() {
    this.onendSession.emit(false);
  }
  showFrame(iframeLink: string) {
    if (iframeLink) {
      // this.zone.run(() => {
      if (this.iframeDialog) {
        this.iframeDialog.close();
      }
      this.iframeDialog = this.dialog.open(ModalIframeDialogComponent, {
        data: {
          data: iframeLink,
          className: 'dialog-frame',
          iframeHeight: '280px'
        }
      });
      this.iframeDialog.disableClose = true;

      // });
      // this.iframeDialog.afterClosed().subscribe(() => {
      // this.highlightNotifications = false;
      // this.highlightNotificationsChange.emit(false);
      // });
    }
  }

  gotoBook() {
    this.router.navigate(['/book/']);
  }

  openGlobalChat() {
    // this.chatService.isGlobalChatShowing = !this.chatService.isGlobalChatShowing;
    this.chatService.isOpenGlobalChat.next(true);

    // debugger;
    if (this.chatService.chatPrivatelyWith && this.chatService.userFlags && this.chatService.userFlags.hasOwnProperty(this.chatService.chatPrivatelyWith.id) && this.chatService.userFlags[this.chatService.chatPrivatelyWith.id].hasOwnProperty('unread') && this.chatService.userFlags[this.chatService.chatPrivatelyWith.id].unread) {
      this.chatService.userFlags[this.chatService.chatPrivatelyWith.id].unread = false;
      this.chatService.unreadChats.next(null);
    }
    //console.log('_isOpenGlobalChat', this._isOpenGlobalChat)
    this.clickingOutside();
    this.detectChanges();
  }

  openAgenda(type = 'current', page = false) {
    if (page || this.isMobile || this.isTablet) {
      this.router.navigateByUrl('/loading', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/agenda/' + type])
      );
    } else {
      this.tabSelected = type;
      this.dialogSchedule.open();
    }
  }
  openSponsors() {
    this.clickingOutside();
    this.router.navigateByUrl('/sponsors');
    // this._isOpenAgenda = !this._isOpenAgenda;
    // this.opentokService.isOpenAgenda.next(this._isOpenAgenda);
    // this.detectChanges();
  }
  openPosters() {
    this.clickingOutside();
    this.router.navigateByUrl('/posters');
  }
  openDonate() {
    if (this.donateLink && this.donateLink !== '') {
      window.open(this.donateLink, '_blank');
    }
  }

  public detectChanges() {
    if (!this.changeDetectorRef['destroyed']) {
      this.changeDetectorRef.detectChanges();
    }
  }

  toggleDnD(showMessage: boolean = true) {
    this.isDnD = !this.isDnD
    this.requestService.saveData('resource/user', { _id: this.selectedUser['_id'], chat_dnd: this.isDnD }, (data, error) => {
      if (error) {
        this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
      }
      if (data) {
        localStorage.setItem('temp-availability-status', this.isDnD + '');
        this.requestService.updateUserData('chat_dnd', this.isDnD);
        this.ablyService.sendUpdateToUsersInMyChannel(this.selectedUser);
        // this.ablyService.publish('dnd', {
        //   senderId: this.selectedUser._id,
        //   value: this.isDnD
        // });
        this.detectChanges();
        //console.log(this.selectedUser)
        if (showMessage)
          this.layoutUtilsService.showNotification(this.translate.instant('Preferences Saved'), this.translate.instant('Dismiss'));
      }
      // this.loading = false;
    }, true);
  }

  goToAttendeeView(e, translator: any, trigger) {
    if (e) {
      e.stopImmediatePropagation();
      e.preventDefault();
    }

    trigger.closeMenu();

    // if (typeof RecordRTC_Extension === 'undefined') {
    //   this.zone.run(() => {
    //     const dialog = this.layoutUtilsService.alertActionElement(this.translate.instant('StellarRecord Extension'), this.translate.instant('StellarRecord chrome extension is either disabled or not installed. Please enable to continue.'), {
    //       overlayClickToClose: false,
    //       showCancelButton: false,
    //       declineText: 'OK'
    //     });
    //     dialog.afterClosed().subscribe((res) => {
    //       // window.location.reload();
    //     });
    //   });
    // }
    // else {
    let channelId = translator.channelId ? translator.channelId : '';
    if (location.host.indexOf('localhost') !== -1)
      window.open('/#/rooms/' + this.roomData._id + '/sessions/' + this.sessionData._id + '/viewAsAttendee/' + channelId, 'Recording', 'noreferrer');
    else
      window.open(environment.serverRecordHostUrl + '/#/login/' + this.requestService.orgId + '?t=' + this.requestService.getToken() + '&returnUrl=' + encodeURIComponent('/rooms/' + this.roomData._id + '/sessions/' + this.sessionData._id + '/viewAsAttendee/' + channelId), 'Recording', 'noreferrer');
    // }
  }
  // }

  toggleHint() {
    this.pageScope.isHintOn = !this.pageScope.isHintOn;
    localStorage.setItem('stellarHints', this.pageScope.isHintOn + '');
  }

  openTeleport() {
    this.isMyAgendaShowing = true;
    this.sessionsFaveLive = [];
    this.loadingSessions = true;
    this.requestService.logTrackEvent(new EventTrack(EventType.CLICK, ClickType.MYAGENDA, undefined, undefined, undefined, undefined), (res, err) => { });
    let filters = {
      "$and": [
        { 'organizationId': { '$eq': this.requestService.orgId } },
        { "type": { "$eq": 'session' } },
        { "userId": { "$eq": this.selectedUser._id } }
      ]
    };
    let filterObj = { page: 1, term: '', orderDir: 'asc', orderBy: 'startsAt', fieldKeys: ['_id', 'favoriteId'], filter: filters };
    this.requestService.getDataList('favorites',
      filterObj, (data, error) => {
        //console.log(data);
        if (error) {
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          let dataListSessionIds = this.requestService.getDataFromList(data.results, 'favoriteId');
          this.loadSessionData(dataListSessionIds)
        } else {
          this.sessionsFaveLive = [];
          this.loadingSessions = false;
        }
      });
  }
  loadSessionData(dataListSessionIds) {
    let filters: any = {
      "$and": [{ "_id": { "$in": dataListSessionIds } }
        // { "active": { "$eq": true } }
      ] //, filter: filters
    };
    let filterObj = { page: 1, orderDir: 'desc', orderBy: 'active', fieldKeys: ['_id', 'name', 'active', 'startsAt', 'users', 'room', 'opentoall', 'recordSource', 'url', 'showbg', 'showLive', 'recordSource', 'recordAvailable'], filter: filters };
    this.requestService.getDataList('session',
      filterObj, (data, error) => {
        //console.log(data);
        if (error) {
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          this.sessionsFaveLive = data.results || [];
        } else {
          this.sessionsFaveLive = [];
        }
        this.loadingSessions = false;
      });
  }
  openSession(currentsession) {
    this.roomSessionService.openSessionById(currentsession._id, (session) => {
      if (session) {
        if (!session.active && session.chainedtosession && session.chainedtosession.active) {
          this.roomSessionService.openSessionById(session.chainedtosession._id, (chainedtosession) => {
            if (chainedtosession) {
              this.roomSessionService.openSession(chainedtosession.room, chainedtosession);
            }
          });
        } else {
          this.roomSessionService.openSession(session.room, session);
        }
      }
    });
  }

  forceRefresh() {
    if (this.isSuperAdmin) {
      const dialogRef = this.dialog.open(ConfirmEntityDialogComponent, {
        disableClose: true,
        data: {
          title: this.translate.instant('Force Refresh'),
          data: '',
          description: this.translate.instant('This will refresh the pages of everyone in the conference. Are you sure you want to proceed?'),
          cancelbtn: this.translate.instant('Cancel'),
          confirmbtn: this.translate.instant('Proceed'),
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // console.log('result', result);
          this.ablyService.publish('forceRefresh', 'true');
        }
      });
    }
  }
  refresh() {
    window.location.reload();
  }

  publishIt() {
    const dialogRef = this.dialog.open(ConfirmEntityDialogComponent, {
      disableClose: true,
      width: '600px',
      data: {
        title: this.translate.instant('Publish Event'),
        data: '',
        description: this.translate.instant('Contact your sales'),
        cancelbtn: this.translate.instant('Close'),
        confirmbtn: this.translate.instant('Go to Payment Gateway')
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let url = this.paymentGateway + '/#/login/' + this.requestService.orgId + '?returnUrl=dashboard&token=' + this.requestService.getToken();
        window.open(url, "_blank");
        // window.location.href = this.paymentGateway;
      }
    });
  }

  openResources() {
    this.router.navigateByUrl('/resources');
  }

  openProfile() {
    this.router.navigateByUrl('/profile');
  }

  closeMyAgenda() {
    this.isMyAgendaShowing = false;
  }
  closeRoomList() {
    this.isRoomListShowing = false;
  }
  openRoomList() {
    this.isRoomListShowing = true;
    this.roomsList = [];
    this.loadingRoomsList = true;
    let filters = {
      "$and": [
        { 'organizationId._id': { '$eq': this.requestService.orgId } },
        { 'opentoall': { '$eq': true } },
        { "type": { "$ne": '3' } }, // exhibit for conference
      ]
    };
    let filterObj = { page: 1, term: '', orderDir: 'asc', orderBy: 'name', fieldKeys: ['_id', 'name', 'owner', 'maparea', 'bgcolor', 'pictureLink', 'opentoall', 'main', 'settings', 'helpdesk', 'showbg', 'emptyRoom'], filter: filters };
    this.requestService.getDataList('room',
      filterObj, (data, error) => {
        //console.log(data);
        if (error) {
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          this.roomsList = data.results;
          // let roomsList = data.results;
          // let dataListIds = this.requestService.getDataFromList(roomsList);
          // if (dataListIds.length > 0) {
          //   this.loadSessionRoomsData(roomsList, dataListIds);
          //   return;
          // }
        } else {
          this.roomsList = [];
        }
        this.loadingRoomsList = false;
      });
  }
  openRoom(room, breakIt = true) {
    this.roomSessionService.openRoom(room, (sessionsList) => {
      if (sessionsList) {
        // do nothing
      }
    }, breakIt);
  }
  openRoomFromList(roomData) { // requested in issue #1184
    let currentRoomUserRole = this.requestService.getUserRoomRole(roomData);
    let roomId = roomData._id;
    let targetUrl = '/rooms/' + roomId + '/sessions';
    if (currentRoomUserRole === 'owner' || currentRoomUserRole === 'admin' || currentRoomUserRole === 'edit') {
      this.router.navigateByUrl('/loading', { skipLocationChange: true }).then(() =>
        this.router.navigate([targetUrl]));
    } else {
      this.openRoom(roomData, false);
    }
  }
  loadSessionRoomsData(roomsList, dataListRoomsIds) {
    let filters: any = {
      "$and": [{ "room._id": { "$in": dataListRoomsIds } },
      { "type": { "$ne": '3' } },
      { 'active': { '$eq': true } }]
    };
    let filterObj = { page: 1, term: '', orderDir: 'asc', orderBy: 'startsAt', fieldKeys: ['_id', 'name', 'active', 'opentoall', 'room', 'showbg'], filter: filters, termfields: ['name', 'users.name', 'tags.name'] };
    this.requestService.getDataList('session',
      filterObj, (data, error) => {
        //console.log(data);
        if (error) {
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          let dataList = data.results || [];
          if (dataList.length > 0) {
            this.roomsList = roomsList.map((rm) => {
              let sessionliveSessions = dataList.filter((ss) => {
                if (ss.room && ss.room._id === rm._id) {
                  return true;
                }
                return false;
              });
              rm['livesession'] = false;
              if (sessionliveSessions.length > 0) {
                rm['livesession'] = true;
              }
              return rm;
            });
          }
        }
        this.loadingRoomsList = false;
      });
  }

  goToSettings() {
    // this.router.navigate(['/rooms/' + this.roomData._id + '/session/' + this.sessionData._id + '/settings']);
    window.location.href = '/#/rooms/' + this.roomData._id + '/session/' + this.sessionData._id + '/settings';
  }

  goToRoomSettings() {
    // this.router.navigate(['/rooms/' + this.roomData._id + '/setting']);
    window.location.href = '/#/rooms/' + this.roomData._id + '/setting';
  }
  goToSession(id, preview) {
    if (preview) {
      this.roomSessionService.openSessionById(id, (currentSession) => {
        if (currentSession) {
          if (currentSession.recordAvailable && currentSession.recordSource && currentSession.recordSource.trim() !== '') {
            const dialogRef = this.dialog.open(ConfirmEntityDialogComponent, {
              disableClose: true,
              width: '600px',
              data: {
                title: this.translate.instant('Attention'),
                data: '',
                description: this.translate.instant('This session has a recording available. Do you want to proceed to the recording or open the session?'),
                cancelbtn: this.translate.instant('Proceed to the recording'),
                confirmbtn: this.translate.instant('Open the session')
              }
            });
            dialogRef.afterClosed().subscribe(result => {
              if (!result) {
                window.open(currentSession.recordSource.trim(), "_blank");
              } else {
                this.roomSessionService.openSession(currentSession.room, currentSession, undefined, undefined, true);
              }
            });
          } else {
            this.roomSessionService.openSession(currentSession.room, currentSession);
          }
        }
      }
      );
    } else {
      this.roomSessionService.openSessionById(id, (currentSession) => {
        if (currentSession) {
          this.roomSessionService.openSession(currentSession.room, currentSession);
        }
      }
      );
    }

  }

  goToRoom(id) {
    this.router.navigate(['/rooms/' + id + '/preview']);
  }

  loadTranslationLanguages() {
    this.sessionData.users.filter(i => i.type === 'interpreter' && i.language).forEach(element => {
      if (!this.availableLanguages.find(i => i.language === element.language))
        this.availableLanguages.push({ channelId: this.sessionData._id + '_' + element.language, language: element.language });
    });

    if (!this.availableLanguages.length)
      this.availableLanguages = [{ channelId: undefined, language: 'en' }];
  }

  clickingOutside() {
    const dom: any = document.querySelector('body');
    dom.classList.remove('push-right');
    dom.classList.remove('right-push-left');
  }
}
