import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule, ErrorHandler, APP_INITIALIZER, InjectionToken } from '@angular/core';
// NGX Permissions
//import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxPermissionsModule } from 'src/app/shared/modules/ngx-permissions';

import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthGuard, CanDeactivateGuard, LayoutService, RequestService, StoreService, LoggerService, GlobalErrorHandler, RoomSessionService, LoaderService, UserDefaultsService, TokBoxService, PictureWallService, MatPaginationIntlService, FeaturesPermissionService, MessagesStatusService, LocalStorageService, RequestExternalService } from 'src/app/shared';
import { LayoutUtilsService, MenuConfigService, SubheaderService, PageScopeService, UrlHelperService, UserActivityService, SchedulerService, rollbarFactory, RollbarService } from 'src/app/shared/services';
//import { BdcWalkModule } from 'bdc-walkthrough';
import { CalendarModule } from 'src/app/shared/modules/calendar';
import { BdcWalkModule } from 'src/app/shared/modules/bdc-walkthrough';
import { SharedModule } from 'src/app/shared/shared.module';
import { LayoutComponentModule } from 'src/app/shared/layout.module';
import { CustomAppRoutingModule } from './app-routing.module';
import { CustomAppComponent } from './app.component';
import { CKEditorModule } from 'ngx-ckeditor';
import { ResizableModule } from 'src/app/shared/directives/angular-resizable-element';
import { ImageCropperModule } from 'ngx-image-cropper';
import { environment } from '../environments/environment';
import { RecorderService } from 'src/app/shared/services/recorder.service';
import { ChartService } from 'src/app/shared/services/chart.service';
import { AblyService } from 'src/app/shared/services/ably.service';
import { ChatService } from 'src/app/shared/services/chat.service';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { SpeedTestModule } from 'ng-speed-test';
import { SpeedCheckService } from 'src/app/shared/services/networkspeed.service';
import { WindowRefService } from 'src/app/shared/services/window-ref.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppModule } from 'src/app/app.module';
import { CustomRequestService, IntegratedService, CustomRequestExternalService } from './shared/services';
import { CustomLayoutComponentModule } from './shared/layout.module';

// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/vertical/' + environment.orgSubType + '/i18n/', '.json');
};

@NgModule({
  declarations: [CustomAppComponent],
  imports: [
    BrowserModule,
    KeyboardShortcutsModule.forRoot(),
    CustomAppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    SharedModule,
    LayoutComponentModule,
    CustomLayoutComponentModule,
    OverlayModule,
    HttpClientModule,
    CKEditorModule,
    ResizableModule,
    BdcWalkModule,
    CalendarModule,
    ImageCropperModule,
    SpeedTestModule,
    NgIdleKeepaliveModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    AuthGuard,
    CanDeactivateGuard,
    { provide: RequestService, useClass: CustomRequestService },
    { provide: RequestExternalService, useClass: CustomRequestExternalService },
    LocalStorageService,
    FeaturesPermissionService,
    MessagesStatusService,
    RoomSessionService,
    PictureWallService,
    LayoutService,
    ChartService,
    TokBoxService,
    RecorderService,
    UserDefaultsService,
    LoaderService,
    AblyService,
    ChatService,
    StoreService,
    LayoutUtilsService,
    MenuConfigService,
    SubheaderService,
    PageScopeService,
    UrlHelperService,
    UserActivityService,
    SchedulerService,
    LoggerService,
    SpeedCheckService,
    WindowRefService,
    IntegratedService,
    // {
    //   provide: ErrorHandler,
    //   useClass: GlobalErrorHandler
    // },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginationIntlService,
    }
  ],
  bootstrap: [CustomAppComponent]
})
export class CustomAppModule extends AppModule { }
