<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="10px" *ngIf="!disableEventBlueprints">
  <div fxFlex>
  </div>
  <div fxFlex="25" align="right">
    <app-custom-select-create-autocomplete #blueprintList *ngIf="filterData" [filters]="filterData"
      [dataType]="dataType" [value]="selectedFilter" [dataTypeDisplay]="'Blueprint'"
      [placeholder]="'Available Event Blueprints'" [canDelete]=" permissions.canDeleteEvent" [simple]="true"
      (onSelectReturn)="selectBlueprint($event._id)" (onReturnSignal)="handleReturnBlueprint($event)" [pageSize]="1000"
      [hasAddBtn]="false" [hasEditBtn]="false" [hasClearBtn]="true" [canNewList]="undefined">
    </app-custom-select-create-autocomplete>
  </div>
</div>
<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="10px">
  <div fxFlex>
    <div style="height: 100%; width: 100%;border: 1px solid gray;">
      <app-calendar *ngIf="dataArray || dataSkelletonArray" #calendarComp [isMobile]="isMobile" [isTablet]="isTablet"
        [showAddButton]="true" [language]="'en'" [dataSource]="dataSource" [eventsActions]="eventsActions"
        [canAddEvent]="permissions.canAddEvent" [bodyHeight]="'calc(100vh - 200px)'"
        [contentTemplate]="customEventTemplate" (dayEvents)="selectDay($event)" (dayEvent)="selectEvent($event)"
        (newEvent)="addEvent($event)" (dayShift)="dayShift($event)" [selectedFilter]="selectedFilter"
        [viewDesignMode]="viewDesignMode"></app-calendar>
    </div>
  </div>
</div>
<ng-template #customEventTemplate let-contentTemplateData="contentTemplateData">
  <div class="eventOverlay" *ngIf="contentTemplateData">
    <div class="eventOverlayTitle" *ngIf="contentTemplateData.name">
      <div>{{ contentTemplateData.eventType === 'blueprint' ? 'Blueprint: ' : '' }} {{contentTemplateData.name}}</div>
    </div>
    <div class="eventOverlayContent" *ngIf="contentTemplateData.startdate && contentTemplateData.enddate">
      <div style="margin: 5px 0px;">From {{contentTemplateData.startdate | date: 'hh:mm aa'}} to
        {{contentTemplateData.enddate | date: 'hh:mm
        aa'}}</div>
      <div style="max-width:30vw" *ngIf="contentTemplateData.description && contentTemplateData.description !== ''">
        {{ (contentTemplateData.description.length>1000)? (contentTemplateData.description |
        slice:0:1000)+'...':(contentTemplateData.description) }}
      </div>
    </div>
  </div>
</ng-template>
<ng-template #customViewEventTemplate let-contentTemplateData="contentTemplateData">
  <mat-list class="eventClass">
    <!-- <mat-list-item class="eventType">
      <app-enum-view [enumValue]="contentTemplateData.eventType" [enumList]="metaFieldSetting['eventType'].enum">
      </app-enum-view>
    </mat-list-item>
    <mat-list-item *ngIf="contentTemplateData.description && contentTemplateData.description !== ''">
      <div class="eventDescription">
        {{contentTemplateData.description}}
      </div>
    </mat-list-item> -->
    <mat-list-item>
      <div class="eventDescription eventMeetingType">
        <app-enum-view [enumValue]="contentTemplateData.settingType" [enumList]="metaFieldSetting['settingType'].enum">
        </app-enum-view> / <app-enum-view [enumValue]="contentTemplateData.meetingType"
          [enumList]="metaFieldSetting['meetingType'].enum">
        </app-enum-view>
      </div>
    </mat-list-item>
    <mat-list-item
      *ngIf="(contentTemplateData.meetingType == '1' && ((contentTemplateData.place == '1' && contentTemplateData.room))) || (contentTemplateData.meetingType == '2' && contentTemplateData.location && contentTemplateData.location !== '')">
      <div class="eventDescription eventLocation">
        <span
          *ngIf="contentTemplateData.place == '1' || (contentTemplateData.meetingType == '2' && contentTemplateData.location && contentTemplateData.location !== '')">{{metaFieldSetting['location'].displayName
          | translate}}: </span> <span *ngIf="contentTemplateData.place == '1'">
          {{contentTemplateData.room.name}}
          <span *ngIf="contentTemplateData.place == '2'">
            {{contentTemplateData.meetingurl}}
          </span>
        </span> <span
          *ngIf="contentTemplateData.meetingType == '2' && contentTemplateData.location && contentTemplateData.location !== ''">{{contentTemplateData.location}}</span>
      </div>
    </mat-list-item>
    <mat-list-item>
      <div class="eventDescription eventstartdate">
        <span>{{metaFieldSetting['startdate'].displayName | translate}}: </span> {{contentTemplateData.startdate | date:
        dateFormat}} at {{contentTemplateData.startdate | date:
        'hh:mm aa'}}
      </div>
    </mat-list-item>
    <mat-list-item>
      <div class="eventDescription eventenddate">
        <span>{{metaFieldSetting['enddate'].displayName | translate}}: </span> {{contentTemplateData.enddate | date:
        dateFormat}} at {{contentTemplateData.enddate | date:
        'hh:mm aa'}}
      </div>
    </mat-list-item>
    <mat-list-item *ngIf="contentTemplateData.attendees && contentTemplateData.attendees.length > 0"
      class="attendeeList">
      <div>
        <div class="eventAttendees eventAttendees">
          <span *ngIf="contentTemplateData.attendeesHost">Host: {{contentTemplateData.attendeesHost.name}}</span>
          <ng-container [ngTemplateOutlet]="attendeeTypes"
            [ngTemplateOutletContext]="{ contentTemplateData: contentTemplateData, contentTemplateDataType: 'lead' }">
          </ng-container>
          <ng-container [ngTemplateOutlet]="attendeeTypes"
            [ngTemplateOutletContext]="{ contentTemplateData: contentTemplateData, contentTemplateDataType: 'guest' }">
          </ng-container>
        </div>
      </div>
    </mat-list-item>
    <mat-list-item
      *ngIf="contentTemplateData.opentoall || (!contentTemplateData.opentoall && ((contentTemplateData.attendeesRoles && contentTemplateData.attendeesRoles.length > 0) || (contentTemplateData.attendeesDataList['attendee'] && contentTemplateData.attendeesDataList['attendee'].length > 0)))">
      <ng-container [ngTemplateOutlet]="openToAllData"
        [ngTemplateOutletContext]="{ contentTemplateData: contentTemplateData }">
      </ng-container>
    </mat-list-item>
    <mat-list-item *ngIf="contentTemplateData.isRecurrence">
      <div class="eventDescription eventRecurrent">
        <span>
          The event is recurrent</span>
      </div>
    </mat-list-item>
    <!-- <mat-list-item>
      <mat-slide-toggle class="example-margin" [color]="'primary'" [checked]="contentTemplateData.enableChat"
        [disabled]="true">
        {{metaFieldSetting['enableChat'].displayName | translate}}
      </mat-slide-toggle>
      <mat-checkbox class="example-margin"
                    [checked]="contentTemplateData.enableChat" [disabled]="true"
                    [color]="'primary'">
                    {{metaFieldSetting['enableChat'].displayName | translate}}
      </mat-checkbox> 
    </mat-list-item>-->
  </mat-list>
</ng-template>
<ng-template #attendeeTypes let-contentTemplateData="contentTemplateData"
  let-contentTemplateDataType="contentTemplateDataType">
  <div
    *ngIf="contentTemplateData.attendeesDataList && contentTemplateData.attendeesDataList[contentTemplateDataType] && contentTemplateData.attendeesDataList[contentTemplateDataType].length > 0">
    {{peopleType[contentTemplateDataType]
    | translate}}: <span
      *ngFor="let ett of contentTemplateData.attendeesDataList[contentTemplateDataType];let index=index">{{ett.name}}<span
        *ngIf="index < contentTemplateData.attendeesDataList[contentTemplateDataType].length - 2">,
      </span>
      <span *ngIf="index === contentTemplateData.attendeesDataList[contentTemplateDataType].length - 2"> and
      </span> </span>
  </div>
</ng-template>
<ng-template #openToAllData let-contentTemplateData="contentTemplateData">
  <div class="eventDescription eventOpentoall">
    <span *ngIf="contentTemplateData.opentoall">This event is open for everyone</span>
    <span
      *ngIf="!contentTemplateData.opentoall && ((contentTemplateData.attendeesRoles && contentTemplateData.attendeesRoles.length > 0) || (contentTemplateData.attendeesDataList['attendee'] && contentTemplateData.attendeesDataList['attendee'].length > 0))">
      This event is open for
      <ng-container *ngIf="contentTemplateData.attendeesRoles && contentTemplateData.attendeesRoles.length > 0">
        <span *ngFor="let ett of contentTemplateData.attendeesRoles;let index=index">{{ett.name}}<span
            *ngIf="(index < contentTemplateData.attendeesRoles.length - 2) || ((index === contentTemplateData.attendeesRoles.length - 2) && (contentTemplateData.attendeesDataList['attendee'] && contentTemplateData.attendeesDataList['attendee'].length > 0))">,
          </span>
          <span
            *ngIf="((index === contentTemplateData.attendeesRoles.length - 2) && (contentTemplateData.attendeesDataList['attendee'] && contentTemplateData.attendeesDataList['attendee'].length === 0))">
            and
          </span>
          <span
            *ngIf="((index === contentTemplateData.attendeesRoles.length - 1) && (contentTemplateData.attendeesDataList['attendee'] && contentTemplateData.attendeesDataList['attendee'].length > 0))">
            and
          </span>
        </span>
      </ng-container>
      <ng-container
        *ngIf="contentTemplateData.attendeesDataList['attendee'] && contentTemplateData.attendeesDataList['attendee'].length > 0">
        {{peopleType['attendee']
        | translate}}s</ng-container>
    </span>
  </div>
</ng-template>