import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class IntegratedService {
  public currentIntegratedProduct: any = undefined;
  public currentIntegratedProductSubject: BehaviorSubject<any | undefined> = new BehaviorSubject<any | undefined>(undefined);
  public loading: boolean = false;

  constructor(private translate: TranslateService, protected router: Router,
    protected activatedRoute: ActivatedRoute) {
  }
  public setIntegratedProduct(integratedProduct) {
    if (integratedProduct) {
      this.currentIntegratedProduct = JSON.parse(JSON.stringify(integratedProduct));
      this.currentIntegratedProductSubject.next(this.currentIntegratedProduct);
    }
  }
  public getProductByType(type) {
    let product: any[] = this.currentIntegratedProduct.products.filter((p) => p.productType === type);
    if (product.length > 0) {
      return product[0];
    } else {
      return undefined;
    }
  }

}