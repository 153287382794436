import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService, LoaderService, FeaturesPermissionService } from '../../../../shared/services';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { isValidHttpUrl } from '../../../../shared/helpers';
import { DefaultSettings } from "src/app/shared/components/layout-components/defaultSettings";
import { CustomSelectDialogComponent } from '../../../../shared/components/custom-select-dialog/custom-select-dialog.component';
import { TileSelectDialogComponent } from '../../../../shared/components/tile-select-dialog/tile-select-dialog.component';
import { ModalTileCreateDialogComponent } from '../../../../shared/components/tile-create-dialog/tile-create-dialog.component';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

interface ViewDialogData {
	title: string;
	data: any;
	confirmData: any;
	hasShape: boolean;
	hasFixed: boolean;
	isEmptyClick: boolean;
}

@Component({
	selector: 'app-picture-dialog-modal',
	templateUrl: './picture-dialog.component.html',
	styleUrls: ['./picture-dialog.component.scss']
})
export class PictureDialogComponent implements OnInit {
	private subscriptions: Subscription[] = [];
	public errorMessage: string = '';
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
	public hasShape: boolean = false;
	public hasFixed: boolean = false;
	public isEmptyClick: boolean = false;
	public targetType: any = DefaultSettings.targetType;
	public targetTypeKeys: any = DefaultSettings.targetTypeKeys;
	public returnData: any = { type: '', link: '', linkDisplay: '', title: '', fixed: false, autoPlay: false, loop: false, volume: 0.5 };
	public pageList: string[] = DefaultSettings.pageList;
	public filterRoomData: any = {
		'$and': [
			{ 'organizationId._id': { '$eq': this.requestService.orgId } }
		]
	};
	public organization = undefined;
	public settingOrgObject = undefined;
	public apiCallSubscription: Subscription = undefined;
	public loadingUsers: boolean = false;
	public filteredUsers: Observable<string[]> = undefined;
	public users: any = [];
	public userFormControl = new FormControl('');

	constructor(
		private requestService: RequestService, private featuresPermissionService: FeaturesPermissionService, private translate: TranslateService,
		private layoutUtilsService: LayoutUtilsService, public dialog: MatDialog,
		public dialogRef: MatDialogRef<PictureDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		//console.log('ViewDialogData', data);
		if (data) {
			this.hasShape = data.hasShape || false;
			this.hasFixed = data.hasFixed || false;
			this.isEmptyClick = data.isEmptyClick || false;
			if (data.data) {
				this.returnData = data.data;
			} else if (this.hasShape) {
				this.returnData['shape'] = 'square';
			}

			if (this.returnData['type'] === 'communicate') {
				this.loadUsers();
			}
		}
	}

	ngOnInit() {
		this.subscriptions.push(
			this.requestService.pageOrganization.subscribe((data) => {
				if (data) {
					this.organization = data;
					this.targetTypeKeys = this.targetTypeKeys.filter((itm) => {
						if (itm === 'integratediframe') {
							if (environment.integratedProduct && this.isEmptyClick) {
								return true;
							} else {
								return false;
							}
						} else {
							return true;
						}
					})
					// if (data.hasOwnProperty('settings')) {
					// 	this.settingOrgObject = data.settings;
					// }
				}
			})
		);
		this.subscriptions.push(
			this.featuresPermissionService.currentSettingsSubject.subscribe((data) => {
				if (data) {
					this.settingOrgObject = data;
				}
			})
		);

		this.filteredUsers = this.userFormControl.valueChanges.pipe(
			startWith(''),
			map(value => this._userFilter(value || '')),
		);
	}
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}
	public checkDisabled(value) {
		if (this.settingOrgObject && this.settingOrgObject.web && this.settingOrgObject.web.topBar) {
			if (this.settingOrgObject.web.topBar.disableSchedule && value === 'agenda') {
				return true;
			} else if (this.settingOrgObject.web.topBar.disableExhibitor && value === 'sponsors') {
				return true;
			} else if (this.settingOrgObject.web.topBar.disableResource && value === 'resources') {
				return true;
			} else if (this.settingOrgObject.web.topBar.disablePoster && value === 'posters') {
				return true;
			} else if (this.settingOrgObject.web.topBar.disableDirectory && value === 'book') {
				return true;
			}
		}
		return false;
	}
	editAttribute($event, id, value) {
		if ($event) {
			$event.preventDefault();
			$event.stopPropagation();
		}
		this.returnData[id] = value;
	}
	public setPictureArrayType(val) {
		this.returnData['type'] = val;
		this.returnData['link'] = '';
		this.returnData['linkDisplay'] = '';
		this.returnData['autoPlay'] = false;
		this.returnData['loop'] = false;
		this.returnData['volume'] = 0.5;

		if (this.returnData['type'] === 'communicate') {
			this.loadUsers();
		}
	}
	closeModal(data): void {
		if (data) {
			// if (!data.link || data.link === '') {
			// 	if (data.type === 'link') {
			// 		this.layoutUtilsService.showNotification(this.translate.instant('Fill in the URL link'), this.translate.instant('Dismiss'));
			// 	} else {
			// 		this.layoutUtilsService.showNotification(this.translate.instant('Select the ' + data['type'] + ' to open'), this.translate.instant('Dismiss'));
			// 	}
			// 	return;
			// }
			if (data.type !== 'integratediframe') {
				if ((!data.link || data.link === '') && this.isEmptyClick) {
					if (data['type'] === 'link') {
						this.layoutUtilsService.showNotification(this.translate.instant('Fill in the URL link'), this.translate.instant('Dismiss'));
						return;
					} else if (data.type && data.type === 'communicate') {
						// do nothing
					} else {
						this.layoutUtilsService.showNotification(this.translate.instant('Select ' + this.targetType[data['type']]), this.translate.instant('Dismiss'));
						return;
					}
				}
				if (data.link && data.link !== '') {
					if (data.type === 'link') {
						if (!isValidHttpUrl(data.link)) {
							this.layoutUtilsService.showNotification(this.translate.instant('Fill in the correct URL link format'), this.translate.instant('Dismiss'));
							return;
						}
					}
				}
			}
		}
		this.dialogRef.close(data);
	}
	public selectPictureLinkType($event, type) {
		if ($event) {
			$event.preventDefault();
			$event.stopPropagation();
		}
		if (type === 'tile') {
			this.selectTile();
		} else if (type === 'room') {
			this.editCustomSelectDialog('room', 'Room');
		} else if (type === 'imagegallery') {
			this.editCustomSelectDialog('gallery', 'Image Gallery');
		} else if (type === 'medialibrary') {
			this.editCustomSelectDialog('video', 'Media Library');
		}
	}
	selectTile() {
		const dialogRef = this.dialog.open(TileSelectDialogComponent, {
			width: '600px',
			autoFocus: false,
			data: {
				title: this.translate.instant('Select') + ' ' + this.translate.instant('Tile'),
				data: {},
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				// console.log('selectTile', result);
				if (result.hasOwnProperty('_id')) {
					this.returnData['link'] = result['_id'];
					this.returnData['linkDisplay'] = result['title'];
				} else if (result.hasOwnProperty('new')) {
					this.createTile();
				} else {
					this.returnData['link'] = '';
					this.returnData['linkDisplay'] = '';
				}
			}
		});
	}
	/**
	* Create Tile popup
	*/
	createTile() {
		let filterData: any = {
			'$and': [
				{ 'organizationId._id': { '$eq': this.requestService.orgId } }
			]
		};
		const dialogRef = this.dialog.open(ModalTileCreateDialogComponent, {
			width: '100vw',
			data: {
				title: this.translate.instant('Create') + ' ' + this.translate.instant('Tile'),
				data: {}
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				if (result.hasOwnProperty('_id')) {
					this.returnData['link'] = result['_id'];
					this.returnData['linkDisplay'] = result['name'];
				} else {
					this.returnData['link'] = '';
					this.returnData['linkDisplay'] = '';
				}
			}
		});
	}
	editCustomSelectDialog(dataType, dataTypeTitle) {
		let filterData: any = {
			'$and': [
				{ 'organizationId._id': { '$eq': this.requestService.orgId } }
			]
		};
		const dialogRef = this.dialog.open(CustomSelectDialogComponent, {
			width: '600px',
			data: {
				title: this.translate.instant('Select') + ' ' + this.translate.instant(dataTypeTitle),
				dataType: dataType,
				dataTypeTitle: dataTypeTitle,
				filters: filterData,
				data: this.returnData['link'],
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				// console.log('editCustomSelectDialog', result);
				if (result.hasOwnProperty('_id')) {
					this.returnData['link'] = result['_id'];
					this.returnData['linkDisplay'] = result['text'];
				} else {
					this.returnData['link'] = '';
					this.returnData['linkDisplay'] = '';
				}
			}
		});
	}

	loadUsers() {
		if (!this.users.length) {
			this.loadingUsers = true;
			let filters = {};
			filters['$and'] = [{ "organizationId._id": { "$eq": this.requestService.orgId } }, { "listedInTheDirectory": { "$eq": "yes" } }];
			let fields = ['_id', 'name', 'isVideoChat', 'isTextChat', 'chat_dnd'];
			let filterObj = { term: '', order: [{ field: 'firstName', order: 'asc' }, { field: 'lastName', order: 'asc' }], fieldKeys: fields, filter: filters };
			this.apiCallSubscription = this.requestService.getUsersList(filterObj, (data, error) => {
				if (error) {
					this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
				}
				if (data) {
					this.users = data.results;
					if (this.returnData.linkDisplay)
						this.userFormControl.setValue(this.returnData.linkDisplay);
					this.loadingUsers = false;
				}
			});
		}
	}

	private _userFilter(value: string): string[] {
		const filterValue = value.toLowerCase();

		return this.users.filter(option => option.name.toLowerCase().includes(filterValue));
	}

	setUserSelection() {
		this.returnData.linkDisplay = this.userFormControl.value;
		this.returnData.link = this.users.find(i => i.name === this.userFormControl.value)?._id;
	}
}
