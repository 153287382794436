import { NgModule } from "@angular/core";
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { BdcWalkModule } from 'bdc-walkthrough';

import { BdcWalkModule } from 'src/app/shared/modules/bdc-walkthrough';
import { CalendarModule } from 'src/app/shared/modules/calendar';
import { LayoutComponentModule } from "src/app/shared/layout.module";
import { CustomTestViewComponent, ModalIntUserDialogComponent } from "./components";

import { Utils } from 'src/app/shared/helpers/utils';

import { CKEditorModule } from 'ngx-ckeditor';
import { ResizableModule } from 'src/app/shared/directives/angular-resizable-element';
import { ImageCropperModule } from 'ngx-image-cropper';

import { NgApexchartsModule } from 'ng-apexcharts';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
@NgModule({
        imports: [
                LayoutComponentModule,
                CommonModule,
                RouterModule,
                SharedModule,
                TranslateModule,
                FormsModule,
                ReactiveFormsModule,
                CKEditorModule,
                BdcWalkModule,
                CalendarModule,
                ResizableModule,
                ImageCropperModule,
                FlexLayoutModule.withConfig({ addFlexToParent: false }),
                NgApexchartsModule,
                // ChartsModule,
                PickerModule
        ],
        providers: [
                // GlobalService
                Utils
        ],
        entryComponents: [
                CustomTestViewComponent,
                ModalIntUserDialogComponent
        ],
        declarations: [
                CustomTestViewComponent,
                ModalIntUserDialogComponent
        ],
        exports: [
                CustomTestViewComponent,
                ModalIntUserDialogComponent
        ]
})
export class CustomLayoutComponentModule { }
