import { Component, OnInit, Input, Output, EventEmitter, HostListener, TemplateRef, ElementRef, ViewChild } from '@angular/core';
import { EventData } from './interface/event-data';
import { yearsLabel } from './locale/years';
import { formatDate } from '@angular/common';
import { mounthsHU } from './locale/monthsHU';
import { weekdayHU } from './locale/weekdaysHU';
import { mounthsEN } from './locale/monthsEN';
import { weekdayEN } from './locale/weekdaysEN';
import { hoursLabel } from './locale/hours';
import { Day } from './interface/day';
import { trigger, transition, style, animate } from '@angular/animations';
import * as moment from 'moment';
import { utc } from 'moment';

declare var rrule: any;

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('250ms', style({ transform: 'translateX(0)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('250ms', style({ transform: 'translateX(100%)', opacity: 0 }))
      ])
    ]
    )

  ]
})
export class CalendarComponent implements OnInit {

  isSmall = false; // not supported

  today = new Date();
  currentMonth;
  currentYear;
  currentWeek;
  currentWeekView;
  currentDay: Day;
  currentWeekData: Day[];
  currentListWeekData: Day[];
  firstDay;
  daysInMonth;
  daysInLastMonth;
  actDay;
  lastMonth;
  actMonth;
  hours: any[] = hoursLabel;
  months: any;
  weekdays: any;
  years = [];
  actFullDate;
  actDate: any;
  actWeekDate: any;
  arrTest = [];
  arrCalendar = [];
  arrListCalendar = [];
  eventsData: any = [];
  eventsSkelletonData: any = [];
  actYear;
  hoverEvent: any = {};
  hoverColumnEvent: any = {};
  showChangeDate = false;
  btnAddShow: boolean;
  private viewModes: any = {
    month: 'Month',
    week: 'Week',
    day: 'Day',
  }
  private viewDesignModes: any = {
    calendar_month: 'Calendar',
    format_list_bulleted: 'List',
  }
  utc = utc;

  @Input() isMobile: boolean = false;
  @Input() isTablet: boolean = false;
  @Input() anchorDate = new Date();
  @Input() viewMode = 'month';
  @Input() viewDesignMode = 'calendar_month';
  @Input() viewModesArray: any[] = Object.keys(this.viewModes);
  @Input() viewDesignModesArray: any[] = Object.keys(this.viewDesignModes);
  @Input() canAddEvent: boolean = false;
  @Input() eventsActions: any[] = []; // {value: 'occurrence', displayName: 'Event Occurrence'}
  public _dataSource: any = { events: <EventData[]>[], skelleton: <EventData[]>[], targetDay: undefined };
  @Input() showAddButton: boolean;
  @Input() language: string = 'en';
  @Input() bodyHeight: string = undefined;
  @Input() selectedFilter: string = undefined;
  @Input() contentListTemplate: TemplateRef<any>;
  @Input() contentTemplate: TemplateRef<any>;
  @Input() dateFormat: string = 'dd-MM-yyyy';
  @Output() dayEvents = new EventEmitter();
  @Output() dayEvent = new EventEmitter();
  @Output() newEvent = new EventEmitter();
  @Output() dayShift = new EventEmitter();

  @Input()
  set dataSource(dataSource: any) {
    this._dataSource = dataSource;
    if (!dataSource.notInitChanges) {
      this.initChanges();
    }
  }
  get dataSource(): any {
    return this._dataSource;
  }
  @ViewChild('targetCalendarElement') targetCalendarElementRef!: ElementRef;
  constructor() {
    this.currentMonth = this.anchorDate.getMonth();
    this.currentYear = this.anchorDate.getFullYear();
    this.currentDay = this.getDay(Number(formatDate(this.anchorDate, 'dd', 'en')), this.currentMonth, this.currentYear);

    this.dayShift.emit({ currentDay: { month: this.currentMonth, year: this.currentYear, day: this.currentDay.day }, viewMode: this.viewMode, currentWeek: this.currentWeek, status: 'init' });
    this.years = yearsLabel;
  }

  ngOnInit() {
    this.actFullDate = formatDate(this.anchorDate, 'yyyy MMMM dd - EEEE', 'en');
    this.actDate = formatDate(this.anchorDate, 'yyyy MMMM', 'en');
    this.actWeekDate = formatDate(this.anchorDate, 'yyyy MMMM', 'en');
    this.actDay = formatDate(this.anchorDate, 'dd', 'en');
    this.actMonth = formatDate(this.anchorDate, 'MM', 'en');
    this.actYear = formatDate(this.anchorDate, 'yyyy', 'en');
    this.eventsData = this.dataSource.events;
    this.eventsSkelletonData = this.buildSkelletonEvents(this.dataSource.skelleton);
    this.btnAddShow = this.showAddButton;
    this.initChanges();
  }

  ngAfterViewInit(): void {
    const height = document.getElementById('cont').offsetHeight;
    const width = document.getElementById('cont').offsetWidth;

    // // TODO: if small only show badges not all the events
    // if (height <= 500 || width <= 769) {
    //   this.isSmall = true;
    // } else {
    //   this.isSmall = false;
    // }

  }

  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   const height = document.getElementById('cont').offsetHeight;
  //   const width = document.getElementById('cont').offsetWidth;
  //   if (height <= 500 || width <= 600) {
  //     this.isSmall = true;
  //   } else {
  //     this.isSmall = false;
  //   }
  // }

  // ngOnChanges() {
  //   // console.log('this.dataSource', this.dataSource);
  //   this.eventsData = this.dataSource.events;
  //   this.eventsSkelletonData = this.buildSkelletonEvents(this.dataSource.skelleton);
  //   this.changeLanguage();
  //   this.createCalendar();
  // } 
  initChanges() {
    // console.log('initChanges', this.dataSource);
    this.eventsData = this.dataSource.events;
    this.eventsSkelletonData = this.buildSkelletonEvents(this.dataSource.skelleton);
    this.changeLanguage();
    this.createCalendar();
    let currentTargetDay = this.currentDay.day;
    let currentTargetMonth = this.currentDay.month;
    let currentTargetYear = this.currentDay.year;
    let currentTargetHour = moment().hour();
    if (this.dataSource.focusDay) {
      let currentTargetDate: any = new Date(this.dataSource.focusDay.startdate);
      currentTargetDay = currentTargetDate.getDate();
      currentTargetMonth = currentTargetDate.getMonth();
      currentTargetYear = currentTargetDate.getFullYear();
      currentTargetHour = currentTargetDate.getHours();
    }
    if (this.dataSource.goToDayMonthBluePrint) {
      let targetDay = this.getNearestBPDay(this.eventsSkelletonData);
      if (targetDay) {
        let currentTargetDate: any = new Date(targetDay.startdate);
        currentTargetDay = currentTargetDate.getDate();
        currentTargetMonth = currentTargetDate.getMonth();
        currentTargetYear = currentTargetDate.getFullYear();
        currentTargetHour = currentTargetDate.getHours();
        this.goToDayMonth(currentTargetMonth, currentTargetYear);
      }
    }
    if (this.dataSource.goToDayMonth) {
      this.goToDayMonth(currentTargetMonth, currentTargetYear);
    }
    setTimeout(() => {
      if (this.viewMode !== 'month') {
        this.focusToHour(currentTargetDay, currentTargetHour);
      } else {
        this.focusToDay(currentTargetDay, currentTargetMonth, currentTargetYear);
      }
    }, 1000);
  }
  getNearestBPDay(eventsData) {
    let targetDay = undefined;
    if (eventsData.length > 0) {
      let eventsDataList = JSON.parse(JSON.stringify(eventsData));
      let diffdate: any = new Date();
      let beforedates = eventsDataList.filter((d) => {
        let currentDate: any = new Date(d.startdate);
        return currentDate - diffdate < 0;
      });
      let afterdates = eventsDataList.filter((d) => {
        let currentDate: any = new Date(d.startdate);
        return currentDate - diffdate >= 0;
      });
      if (afterdates.length > 0) {
        targetDay = afterdates[0];
      } else if (beforedates.length > 0) {
        targetDay = beforedates[beforedates.length - 1];
      }
    }
    return targetDay;
  }
  buildSkelletonEvents(skelletonData) {
    let skelletonDataTemp = JSON.parse(JSON.stringify(skelletonData));
    for (let itm of skelletonData) {
      if (itm.isRecurrence && itm.recurrence) {
        try {
          itm.recurrence['startdate'] = itm["startdate"]; //we add this to force start date to be taken from event in case it didn't
          let ruleTextAll = this.getRecurrenceDatas(itm.recurrence).all;
          let startdate = moment(itm["startdate"]).utc();
          let endEventdate = moment(itm["enddate"]).utc();
          let minutes = endEventdate.diff(startdate, 'minutes');
          if (ruleTextAll.length > 1) {
            ruleTextAll.shift();
            for (let date of ruleTextAll) {
              let endEventdate = moment(date).utc();
              endEventdate.add(minutes, 'minutes');
              let eventObj = Object.assign({}, itm);
              eventObj["startdate"] = moment(date).utc().format('YYYY-MM-DDTHH:mm');
              eventObj["enddate"] = endEventdate.format('YYYY-MM-DDTHH:mm');
              skelletonDataTemp.push(eventObj);
            }
          }

        } catch (e) {
          console.log('setRuleText', e);
        }
      }

    }
    // console.log('skelletonDataTemp', skelletonDataTemp);
    return skelletonDataTemp;
  }
  getDay(day, month, year, event = []) {
    return {
      day: day,
      month: month,
      year: year,
      events: event,
      eventsView: []
    }
  }
  goToToday() {
    this.currentMonth = this.today.getMonth();
    this.currentYear = this.today.getFullYear();
    this.currentDay = this.getDay(Number(formatDate(this.today, 'dd', 'en')), this.currentMonth, this.currentYear);
    // this.currentWeek = this.getWeekIndex(this.currentDay);
    this.currentWeek = undefined;
    this.createCalendar();
    this.dayShift.emit({ currentDay: { month: this.currentMonth, year: this.currentYear, day: this.currentDay.day }, viewMode: this.viewMode, currentWeek: this.currentWeek, status: 'change' });
    if (this.viewMode !== 'month') {
      let currentTargetHour = moment().hour();
      this.focusToHour(this.currentDay.day, currentTargetHour);
    } else {
      this.focusToDay(this.currentDay.day, this.currentDay.month, this.currentDay.year);
    }
  }

  focusToDay(day, month, year) {
    if (this.targetCalendarElementRef) {
      const elementInsideTarget = this.targetCalendarElementRef.nativeElement.querySelector('#cell-item-' + day + '-' + month + '-' + year);
      if (elementInsideTarget) {
        elementInsideTarget.scrollIntoView({ behavior: 'smooth' });
      }
    }

  }
  focusToHour(day, currentTargetHour) {
    if (this.targetCalendarElementRef) {
      let hour = currentTargetHour.toString().padStart(2, '0');
      const elementInsideTarget = this.targetCalendarElementRef.nativeElement.querySelector('#hr-cell-item-' + day + '-' + hour);
      if (elementInsideTarget) {
        elementInsideTarget.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
  viewModeChange(val) {
    setTimeout(() => {
      if (val !== 'month') {
        let currentTargetHour = moment().hour();
        this.focusToHour(this.currentDay.day, currentTargetHour);
      } else {
        this.focusToDay(this.currentDay.day, this.currentDay.month, this.currentDay.year);
      }
    }, 1000);
  }

  createCalendar(getWeekByCurrentDate = true) {
    this.arrTest = [];
    this.arrCalendar = [];
    this.firstDay = new Date(this.currentYear, this.currentMonth).getUTCDay();
    this.daysInMonth = this.getDaysInMonth(this.currentMonth, this.currentYear);
    this.daysInLastMonth = this.getDaysInMonth(
      this.currentMonth - 1,
      this.currentYear
    );
    const lmd = this.daysInLastMonth - (this.firstDay - 1);

    let eventsData = this.prepareViewEvent(this.eventsData);
    let eventsSkelletonData = this.prepareViewEvent(this.eventsSkelletonData);

    // Last month days
    for (let index = lmd; index <= this.daysInLastMonth; index++) {
      this.updateEventArray(eventsData, eventsSkelletonData, index, this.currentMonth - 1, this.currentYear);
    }
    // Actual month
    for (let index = 1; index <= this.daysInMonth; index++) {
      this.updateEventArray(eventsData, eventsSkelletonData, index, this.currentMonth, this.currentYear);
    }

    for (let i = this.arrTest.length, j = 1; i < 42; i++, j++) {
      this.updateEventArray(eventsData, eventsSkelletonData, j, this.currentMonth + 1, this.currentYear);
    }

    for (let i = 0; i < 6; i++) {
      const arrWeek = this.arrTest.splice(0, 7);
      if (i === 5) {
        let isLastWeekNextMonth = arrWeek.filter((itm) => itm.month !== this.currentMonth + 1);
        if (isLastWeekNextMonth.length > 0) {
          this.arrCalendar.push(arrWeek);
        }
      } else {
        this.arrCalendar.push(arrWeek);
      }
    }
    // console.log('Data 1', {currentDay:this.currentDay, currentMonth: this.currentMonth, currentYear:this.currentYear}); && this.currentWeek === undefined
    // if (this.currentWeek === undefined) {
    let currentWeek = this.currentWeek;
    if (getWeekByCurrentDate && this.currentWeek === undefined) {
      currentWeek = this.getWeekIndex(this.currentDay);
    }
    if (this.arrCalendar.length <= this.currentWeek) {
      currentWeek = this.arrCalendar.length;
    }
    this.currentWeek = currentWeek;
    // }
    this.currentWeekData = this.arrCalendar[this.currentWeek];
    if (this.currentWeekData) {
      for (let cellItem of this.currentWeekData) {
        cellItem = this.enhanceDayView(cellItem);
      }
    }

    let totalWeeks = this.getWeekNumber(this.currentYear, this.currentMonth);
    if (this.currentWeek >= (totalWeeks - 1)) {
      let currentMonth = this.currentMonth + 1;
      let currentYear = this.currentYear;
      if (currentMonth >= 12) {
        currentMonth = 0;
        currentYear = this.currentYear + 1;
      }
      this.currentWeekView = 0;
      this.actWeekDate = this.creatActMonthYear(currentYear, currentMonth);
    } else {
      this.currentWeekView = this.currentWeek;
      this.actWeekDate = this.creatActMonthYear();
    }
    this.actDate = this.creatActMonthYear();
    this.actFullDate = this.creatActFullDay();

    this.arrListCalendar = this.prepareListEvent(JSON.parse(JSON.stringify(this.arrCalendar)));
    this.currentListWeekData = this.arrListCalendar[this.currentWeek];

    // console.log('Data 2', { arrCalendar: this.arrCalendar, currentWeek: this.currentWeek, daysInMonth: this.daysInMonth, daysInLastMonth: this.daysInLastMonth });
  }
  enhanceDayView(cellItem) {
    cellItem['eventsView'] = [];
    let currentColumn = [[]];
    for (let event of cellItem.events) {
      let gotInserted = false;
      for (let col of currentColumn) {
        if (col.length === 0 || !this.coincideEvents(event, col)) {
          col.push(event);
          gotInserted = true;
          break;
        }
      }
      if (!gotInserted) {
        currentColumn.push([event]);
      }
    }
    cellItem['eventsView'] = currentColumn;
    return cellItem;
  }
  coincideEvents(event, eventList) {
    for (let itm of eventList) {
      if (new Date(event.startdate).getTime() < new Date(itm.enddate).getTime() && new Date(event.enddate).getTime() > new Date(itm.startdate).getTime()) {
        return true; // Overlap found
      }
    }
    return false;
  }
  updateEventArray(dataList, skelletonDataList, index, month, year) {
    const filterDataList = dataList.filter(event => {
      return (
        new Date(event.startdate).getTime() <
        new Date(
          year,
          month,
          index + 1
        ).getTime() &&
        new Date(event.enddate).getTime() >=
        new Date(year, month, index).getTime()
      );
    });
    const filterSkelletonDataList = skelletonDataList.filter(event => {
      return (
        new Date(event.startdate).getTime() <
        new Date(
          year,
          month,
          index + 1
        ).getTime() &&
        new Date(event.enddate).getTime() >=
        new Date(year, month, index).getTime()
      );
    });

    let filterDataListAll = [];
    // const filterDataListAll = filterDataList.concat(filterSkelletonDataList);
    if (this.selectedFilter) {
      filterDataListAll = filterSkelletonDataList.concat(filterDataList);
    } else {
      filterDataListAll = filterDataList;
    }
    //Sorted events by date
    const arrSortedDataByDate = filterDataListAll.sort((a: any, b: any) => {
      return a.startdate - b.startdate
    });

    this.arrTest.push({
      day: index,
      month: month,
      year: year,
      dayName: this.getDayName(year, month, index),
      events: arrSortedDataByDate,
      date: this.createDateWithFormat(year, month, index),

    });
    if (index === this.currentDay.day && month === this.currentDay.month && year === this.currentDay.year) {
      this.currentDay['events'] = arrSortedDataByDate;
      this.currentDay['date'] = this.createDateWithFormat(year, month, index);
      this.currentDay = this.enhanceDayView(this.currentDay);
      // console.log('this.currentDay', this.currentDay);
    }
  }
  getDayName(year, month, day) {
    // var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let d = new Date(year, month, day);
    let index = d.getDay();
    if (index === 0) {
      index = 6;
    } else {
      index--;
    }
    let dayName = this.weekdays[index];
    return dayName;
  }
  createDateWithFormat(year, month, day) {
    // Use the moment() constructor to create a moment object with the given year, month, and day
    const date = moment().local().set({
      year: year,
      month: month, // Months in moment.js are zero-indexed (0-11)
      date: day,
    });

    // Format the date as 'YYYY-MM-DD'
    const formattedDate = date.format('YYYY-MM-DD');
    return formattedDate;
  }
  prepareListEvent(arrListCalendar: any[]) {
    let eventsId: string[] = [];
    for (let i = 0; i < arrListCalendar.length; i++) {
      arrListCalendar[i] = arrListCalendar[i].filter((itm) => {
        if (itm.month === this.currentMonth) {
          return true;
        } else {
          return false;
        }
      });
      for (let j = 0; j < arrListCalendar[i].length; j++) {
        arrListCalendar[i][j].events = arrListCalendar[i][j].events.filter((event) => {
          if (!eventsId.includes(event._id)) {
            eventsId.push(event._id);
            return true;
          } else {
            return false;
          }
        })
      }
    }
    return arrListCalendar;
  }
  prepareViewEvent(eventsData: any[]) {
    let eventNewData = JSON.parse(JSON.stringify(eventsData));
    eventNewData = eventNewData.map(event => {
      let start = moment.utc(event.startdate).local();
      let end = moment.utc(event.enddate).local();
      event.startdate = start.format('YYYY-MM-DDTHH:mm');
      event.enddate = end.format('YYYY-MM-DDTHH:mm');
      let startHour = Number(formatDate(event.startdate, 'HH', 'en'));
      let startMinute = Number(formatDate(event.startdate, 'mm', 'en'));
      let endHour = Number(formatDate(event.enddate, 'HH', 'en'));
      let endMinute = Number(formatDate(event.enddate, 'mm', 'en'));
      // let startYear = Number(formatDate(event.startdate, 'YYYY', 'en'));
      // let startMonth = Number(formatDate(event.startdate, 'MM', 'en'));
      // let startDay = Number(formatDate(event.startdate, 'dd', 'en'));
      // let endYear = Number(formatDate(event.enddate, 'YYYY', 'en'));
      // let endMonth = Number(formatDate(event.enddate, 'MM', 'en'));
      // let endDay = Number(formatDate(event.enddate, 'dd', 'en'));
      event['arrayView'] = {};
      if (start.isSame(end, 'day')) {
        // if (startYear === endYear && startMonth === endMonth && startDay === endDay) {
        event['sameDay'] = true;
        let viewInfo = this.getEventDayView({ startHour, startMinute, endHour, endMinute });
        event['arrayView'][start.format('YYYY-MM-DD')] = viewInfo;
      } else {
        event['sameDay'] = false;
        for (let currentDate = moment(start); currentDate.isSameOrBefore(end, 'day'); currentDate.add(1, 'day')) {
          let currentStartHour = startHour;
          let currentEndHour = endHour;
          let currentStartMinute = startMinute;
          let currentEndMinute = endMinute;
          if (!start.isSame(currentDate, 'day')) {
            currentStartHour = 0;
            currentStartMinute = 0;
          }
          if (!end.isSame(currentDate, 'day')) {
            currentEndHour = 24;
            currentEndMinute = 0;
          }
          let viewInfo = this.getEventDayView({ startHour: currentStartHour, startMinute: currentStartMinute, endHour: currentEndHour, endMinute: currentEndMinute });
          event['arrayView'][currentDate.format('YYYY-MM-DD')] = viewInfo;
        }
      }
      return event;
    });
    return eventNewData;
  }
  getEventDayView(event: { startHour, startMinute, endHour, endMinute }) {
    let returnObj = { topMargin: '0', height: '0' };

    let topMargin = (event.startHour * 60) + (event.startHour * 2);

    if (event.startMinute >= 15) {
      topMargin = topMargin + event.startMinute;
    }
    returnObj['topMargin'] = (topMargin + 2) + 'px';
    let height = (event.endHour * 60) + (event.endHour * 2) - 4;
    if (event.endMinute >= 15) {
      height = height + event.endMinute;
    }
    height = height - topMargin;
    if (height <= 0) {
      height = 15;
    }
    returnObj['height'] = height + 'px';
    return returnObj;
  }
  getWeekData(inputDay: Day) {
    for (let week of this.arrCalendar) {
      for (let day of week) {
        if (day.day === inputDay.day && day.month === inputDay.month && day.year === inputDay.year) {
          return week;
        }
      }
    }
    return [];
  }
  getWeekIndex(inputDay: Day) {
    let index = 0;
    for (let week of this.arrCalendar) {
      for (let day of week) {
        if (day.day === inputDay.day && day.month === inputDay.month && day.year === inputDay.year) {
          return index;
        }
      }
      index = index + 1;
    }
    return index - 1;
  }

  getDaysInMonth(iMonth, iYear) {
    return 32 - new Date(iYear, iMonth, 32).getDate();
  }
  getWeekNumber(year, monthNumber) {

    var firstOfMonth = new Date(year, monthNumber, 1);
    var lastOfMonth = new Date(year, monthNumber + 1, 0);

    var used = firstOfMonth.getDay() + lastOfMonth.getDate();

    return Math.ceil(used / 7);
  }
  previousButtonClick() {
    if (this.viewMode === 'month') {
      if (this.currentMonth === 0) {
        this.currentYear -= 1;
        this.currentMonth = 11;
      } else {
        this.currentMonth -= 1;
      }
      this.createCalendar();
    } else if (this.viewMode === 'week') {
      if (this.currentWeek === 0) {
        this.currentMonth -= 1;
        if (this.currentMonth <= -1) {
          this.currentMonth = 11;
          this.currentYear -= 1;
        }
        let totalWeeks = this.getWeekNumber(this.currentYear, this.currentMonth);
        if (totalWeeks > 4) {
          totalWeeks = totalWeeks - 1;
        }
        this.currentWeek = (totalWeeks - 1);
      } else {
        this.currentWeek -= 1;
      }
      this.createCalendar(false);
    } else {
      if (this.currentDay.day === 1) {
        this.currentMonth -= 1;
        if (this.currentMonth <= -1) {
          this.currentMonth = 11;
          this.currentYear -= 1;
        }
        this.currentDay.year = this.currentYear;
        this.currentDay.month = this.currentMonth;
        this.currentDay.day = this.daysInLastMonth;
      } else {
        this.currentDay.day -= 1;
      }
      this.createCalendar();
    }
    this.dayShift.emit({ currentDay: { month: this.currentMonth, year: this.currentYear, day: this.currentDay.day }, viewMode: this.viewMode, currentWeek: this.currentWeek, status: 'change' });
  }
  goToDayMonth(month, year) {
    this.viewMode = 'month';
    this.currentYear = year;
    this.currentMonth = month;
    this.createCalendar();
  }
  nextButtonClick() {
    if (this.viewMode === 'month') {
      if (this.currentMonth >= 11) {
        this.currentYear += 1;
        this.currentMonth = 0;
      } else {
        this.currentMonth += 1;
      }
      this.currentWeek = 0;
      this.createCalendar();
    } else if (this.viewMode === 'week') {
      let totalWeeks = this.getWeekNumber(this.currentYear, this.currentMonth);
      if (totalWeeks > 4) {
        totalWeeks = totalWeeks - 1;
      }
      if (this.currentWeek >= (totalWeeks - 1)) {
        this.currentMonth += 1;
        if (this.currentMonth >= 12) {
          this.currentMonth = 0;
          this.currentYear += 1;
        }
        if (this.currentWeek === (totalWeeks - 1)) {
          this.currentWeek = 0;
        } else {
          this.currentWeek = 1;
        }
      } else {
        this.currentWeek += 1;
      }
      this.createCalendar(false);
    } else {
      if (this.currentDay.day >= this.daysInMonth) {
        this.currentMonth += 1;
        if (this.currentMonth >= 12) {
          this.currentMonth = 0;
          this.currentYear += 1;
        }
        this.currentDay.year = this.currentYear;
        this.currentDay.month = this.currentMonth;
        this.currentDay.day = 1;
      } else {
        this.currentDay.day += 1;
      }
      this.createCalendar();
    }
    this.dayShift.emit({ currentDay: { month: this.currentMonth, year: this.currentYear, day: this.currentDay.day }, viewMode: this.viewMode, currentWeek: this.currentWeek, status: 'change' });
  }


  // Dialog test
  // TODO: return the selected value
  selectCellItem(day) {
    this.currentDay = day;
    this.dayEvents.emit(day);
  }
  selectCellItemHours(day, hr, half: boolean = false) {
    this.currentDay = day;
    if (half) {
      hr = hr + ':30';
    } else {
      hr = hr + ':00';
    }
    let dayData = JSON.parse(JSON.stringify(day));
    dayData['hour'] = hr;
    this.dayEvents.emit(dayData);
  }
  selectEventItem(e, day, event) {
    e.stopImmediatePropagation();
    e.preventDefault();
    let dayData = JSON.parse(JSON.stringify(day));
    dayData['event'] = event;
    this.dayEvent.emit(dayData);
  }

  changeLanguage() {
    if (!this.language) {
      this.language = 'en';
      this.months = mounthsEN;
      this.weekdays = weekdayEN;
    }
    if (this.language === 'hu') {
      this.months = mounthsHU;
      this.weekdays = weekdayHU;
    } else {
      this.months = mounthsEN;
      this.weekdays = weekdayEN;
    }
  }

  onYearChange(event) {
    this.currentYear = Number(event.value);
    this.createCalendar();
    this.dayShift.emit({ currentDay: { month: this.currentMonth, year: this.currentYear, day: this.currentDay.day }, viewMode: this.viewMode, currentWeek: this.currentWeek, status: 'change' });
  }

  onMonthChange(event) {
    this.currentMonth = Number(event.value);
    this.createCalendar();
    this.dayShift.emit({ currentDay: { month: this.currentMonth, year: this.currentYear, day: this.currentDay.day }, viewMode: this.viewMode, currentWeek: this.currentWeek, status: 'change' });
  }

  creatActMonthYear(currentYear = this.currentYear, currentMonth = this.currentMonth) {
    const actDate = formatDate(
      new Date(currentYear, currentMonth),
      'yyyy MMMM',
      'en'
    );

    return actDate;
  }
  creatActFullDay() {
    const actDate = formatDate(
      this.getFullDay(),
      'yyyy MMMM dd  - EEEE',
      'en'
    );

    return actDate;
  }
  getFullDay() {
    return new Date(this.currentYear, this.currentMonth, this.currentDay.day);
  }

  createEvent(action) {
    const testMessage = this.getFullDay();
    this.newEvent.emit({ action: action, testMessage });
  }
  getRecurrenceDatas(recurObj) {
    let response = { humantext: '', all: undefined };
    if (!recurObj) {
      return response;
    } else {
      let pattern = "";
      let count = 0;
      switch (recurObj["repeats"]) {
        case "minutely":
          pattern = "FREQ=MINUTELY;INTERVAL=" + recurObj["interval"];
          break;
        case "hourly":
          pattern = "FREQ=HOURLY;INTERVAL=" + recurObj["interval"];
          break;
        case "daily":
          pattern = "FREQ=DAILY;INTERVAL=" + recurObj["interval"];
          break;
        case "weekly":
          pattern = "FREQ=WEEKLY;INTERVAL=" + recurObj["interval"] + ";BYDAY=" + recurObj["byweekday"].toString();
          break;
        case "monthly":
          pattern = "FREQ=MONTHLY;INTERVAL=" + recurObj["interval"];
          if (recurObj["monthlyType"] && recurObj["monthlyType"] == "dayofmonth") {
            pattern += ";BYMONTHDAY=" + recurObj["bymonthday"].toString();
          } else {
            pattern += ";BYSETPOS=" + recurObj["bysetpos"] + ";BYDAY=" + recurObj["byday"].toString();
          }
          break;
        case "yearly":
          pattern = "FREQ=YEARLY;"
          if (recurObj["yearlyType"] && recurObj["yearlyType"] == "dayofmonth") {
            pattern += "BYMONTH=" + recurObj["bymonth"] + ";BYMONTHDAY=" + recurObj["bymonthday"].toString();
          } else {
            pattern += "BYDAY=" + recurObj["byday"] + ";BYSETPOS=" + recurObj["bysetpos"] + ";BYMONTH=" + recurObj["bymonth"].toString();
          }
          break;
        default:
          pattern = "";
      }
      if (recurObj["end"] && recurObj["end"] == "after") {
        pattern += ";COUNT=" + recurObj["count"];
        count = recurObj["count"];
      }
      if (recurObj["end"] && recurObj["end"] == "date") {
        pattern += ";UNTIL=" + moment(recurObj["until"]).utc().format('YYYYMMDDTHHmmss')
      }
      // console.log('pattern', pattern);
      let options = rrule.RRule.parseString(pattern);
      // options.dtstart = new Date(recurObj["startdate"]);
      options.dtstart = moment(recurObj['startdate']).utc().toDate();
      if (count && count != 0) {
        options.count = count;
      } else {
        // options.until = new Date(recurObj["until"]);
        options.until = moment(recurObj['until']).utc().toDate();
      }
      var rule = new rrule.RRule(options);
      // return rule.toText();
      var all = rule.all();
      response["humantext"] = rule.toText();
      response["all"] = all;
    }
    return response;
  }
}
