<div class="col-xl-12" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content>
		<mat-card>
			<div class="example-full-width">
				<mat-form-field type="text" style="width: 100%;">
					<input matInput placeholder="{{ 'Title' | translate }}" [(ngModel)]="returnData['title']">
				</mat-form-field>
			</div>
			<mat-form-field class="example-full-width">
				<mat-label>Link To</mat-label>
				<mat-select (selectionChange)="setPictureArrayType($event.value)" [value]="returnData['type']">
					<mat-option *ngFor="let trg of targetTypeKeys" value="{{trg}}">{{targetType[trg] | translate}}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<div class="example-full-width" *ngIf="returnData['type'] !== 'none'">
				<mat-form-field type="url" style="width: 100%;" *ngIf="returnData['type'] === 'link'">
					<input matInput placeholder="{{ 'Link' | translate }}{{ isEmptyClick ? '*':''}}"
						[(ngModel)]="returnData['link']">
				</mat-form-field>
				<!-- <mat-form-field type="text" style="width: 100%;" *ngIf="returnData['type'] === 'page'">
							<input matInput placeholder="{{ 'Link' | translate }}*" [(ngModel)]="returnData['link']">
					</mat-form-field> -->
				<mat-form-field type="text" style="width: 100%;"
					*ngIf="returnData['type'] === 'tile' || returnData['type'] === 'room' || returnData['type'] === 'imagegallery'|| returnData['type'] === 'medialibrary'">
					<input matInput [readonly]="true" [(ngModel)]="returnData['linkDisplay']"
						placeholder="{{ 'Select' | translate }} {{targetType[returnData['type']] | translate}}{{ isEmptyClick ? '*':''}}">
					<mat-icon matSuffix style="cursor:pointer"
						(click)="selectPictureLinkType($event, returnData['type'])">mode_edit</mat-icon>
				</mat-form-field>
				<!-- <app-custom-select *ngIf="returnData['type'] === 'room'" [value]="returnData['link']" [itemName]="'datacustom'" [dataType]="'room'" [apiTarget]="'searchObject'" placeholder="{{ 'Select' | translate }} {{returnData['type']}}*" [filters]="filterRoomData" (onSelectReturn)="selectRoomItemCheck($event)"></app-custom-select> -->
				<mat-form-field class="example-full-width" *ngIf="returnData['type'] === 'page'">
					<mat-label>{{'Select Page' | translate}}*</mat-label>
					<mat-select (selectionChange)="returnData['link'] = $event.value" [value]="returnData['link']">
						<ng-container *ngFor="let pg of pageList">
							<mat-option value="{{pg.value}}" *ngIf="!checkDisabled(pg.value)">{{pg.name | translate}}
							</mat-option>
						</ng-container>
					</mat-select>
				</mat-form-field>
				<mat-spinner [diameter]="20" *ngIf="loadingUsers && returnData['type'] === 'communicate'"
					style="margin: 0 auto;"></mat-spinner>
				<mat-form-field *ngIf="!loadingUsers && returnData['type'] === 'communicate'" style="width: 100%;">
					<mat-label>{{'Select User' | translate}}*</mat-label>
					<input type="text" placeholder="{{'Select User' | translate}}" matInput [matAutocomplete]="auto"
						[formControl]="userFormControl">
					<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
						(optionSelected)="setUserSelection()">
						<mat-option *ngFor="let user of filteredUsers | async" [value]="user.name">
							{{user.name}}
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>
			</div>
			<!-- <mat-form-field class="example-full-width" *ngIf="returnData['type'] !== 'none'">
					<input name="link" matInput
					 [readonly]="returnData['type'] !== 'link' && returnData['type'] !== 'page'" [(ngModel)]="returnData['link']">
					<mat-icon matSuffix *ngIf="returnData['type'] !== 'link' && returnData['type'] !== 'page'" style="cursor:pointer" (click)="selectPictureLinkType($event, returnData['type'])">mode_edit</mat-icon>
				</mat-form-field> -->
			<ng-container *ngIf="hasShape">
				<mat-form-field class="example-full-width">
					<mat-label>Shape</mat-label>
					<mat-select (selectionChange)="returnData['shape'] = $event.value" [value]="returnData['shape']">
						<mat-option value="square">{{'Rectangle' | translate}}</mat-option>
						<mat-option value="circle">{{'Oval' | translate}}</mat-option>
						<mat-option value="poly">{{'Poly' | translate}}</mat-option>
					</mat-select>
				</mat-form-field>
			</ng-container>
			<div class="fieldInput" *ngIf="hasFixed">
				<mat-icon *ngIf="!returnData['fixed']" (click)="editAttribute($event,'fixed', true)"
					title="{{'A fixed component cannot be edited by the producer during the session.' | translate}}"
					style="height: 30px;vertical-align: middle;cursor:pointer">check_box_outline_blank</mat-icon>
				<mat-icon *ngIf="returnData['fixed']" (click)="editAttribute($event, 'fixed', false)"
					title="{{'A fixed component cannot be edited by the producer during the session.' | translate}}"
					style="height: 30px;vertical-align: middle;cursor:pointer">check_box</mat-icon>
				<label for="fixed{{subIndex}}"> {{'Fixed component' | translate}}</label>
			</div>
			<div class="fieldInput" *ngIf="!isEmptyClick">
				<mat-icon *ngIf="returnData['notFixedRatio']" (click)="editAttribute($event,'notFixedRatio', false)"
					title="{{'Fix component ratio based on image ratio.' | translate}}"
					style="height: 30px;vertical-align: middle;cursor:pointer">check_box_outline_blank</mat-icon>
				<mat-icon *ngIf="!returnData['notFixedRatio']" (click)="editAttribute($event, 'notFixedRatio', true)"
					title="{{'Fix component ratio based on image ratio.' | translate}}"
					style="height: 30px;vertical-align: middle;cursor:pointer">check_box</mat-icon>
				<label for="notFixedRatio{{subIndex}}"> {{'Fixed image ratio' | translate}}</label>
			</div>
			<div class="example-full-width" *ngIf="returnData['type'] === 'medialibrary'">
				<mat-label>{{'Volume' | translate}}</mat-label>
				<mat-slider min="0" max="1" step="0.1" [thumbLabel]="true" [(ngModel)]="returnData['volume']">
				</mat-slider>
			</div>
			<div class="example-full-width" *ngIf="returnData['type'] === 'medialibrary'">
				<mat-icon *ngIf="!returnData['autoPlay']" (click)="returnData['autoPlay']=true"
					title="{{'Auto Play' | translate}}" style="height: 30px;vertical-align: middle;cursor:pointer">
					check_box_outline_blank</mat-icon>
				<mat-icon *ngIf="returnData['autoPlay']" (click)="returnData['autoPlay']=false"
					title="{{'Auto Play' | translate}}" style="height: 30px;vertical-align: middle;cursor:pointer">
					check_box</mat-icon>
				<label style="width: 13vw" for="autoPlay"> {{'Auto Play' | translate}}</label>
			</div>
			<div class="example-full-width" *ngIf="returnData['type'] === 'medialibrary'">
				<mat-icon *ngIf="!returnData['loop']" (click)="returnData['loop']=true" title="{{'Loop' | translate}}"
					style="height: 30px;vertical-align: middle;cursor:pointer">check_box_outline_blank</mat-icon>
				<mat-icon *ngIf="returnData['loop']" (click)="returnData['loop']=false" title="{{'Loop' | translate}}"
					style="height: 30px;vertical-align: middle;cursor:pointer">check_box</mat-icon>
				<label style="width: 13vw" for="loop"> {{'Loop' | translate}}</label>
			</div>
		</mat-card>
	</div>
	<div mat-dialog-actions>
		<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
		<button mat-raised-button (click)="closeModal(returnData)">{{'Confirm' | translate}}</button>
	</div>
	<br />
</div>