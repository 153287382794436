import { Component, HostListener, Inject, NgZone, OnInit, Renderer2, AfterViewInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable, interval, fromEvent } from 'rxjs';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { RequestService, StoreService, LoggerService, LoaderService, FeaturesPermissionService, MenuConfigService, LayoutUtilsService, UserActivityService } from 'src/app/shared/services';
import { ShortcutInput } from "ng-keyboard-shortcuts";
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import * as _ from 'lodash';
import { SwUpdate } from '@angular/service-worker';
import { AppComponent } from 'src/app/app.component';
import { CustomRequestService, IntegratedService } from './shared/services';


@Component({
  selector: 'app-root',
  templateUrl: '../app/app.component.html',
  styleUrls: ['../app/app.component.scss']
})
export class CustomAppComponent extends AppComponent implements OnInit, AfterViewInit {
  public shortcuts: ShortcutInput[] = [];
  constructor(protected menuConfigService: MenuConfigService, protected meta: Meta, protected title: Title, protected loaderService: LoaderService, public snackBar: MatSnackBar, protected translate: TranslateService, @Inject(RequestService) protected requestService: CustomRequestService, protected logger: LoggerService, protected router: Router, protected dialog: MatDialog, protected layoutUtilsService: LayoutUtilsService, protected featuresPermissionService: FeaturesPermissionService, protected userActivityService: UserActivityService, protected idle: Idle, protected swUpdate: SwUpdate, public integratedService: IntegratedService) {
    super(menuConfigService, meta, title, loaderService, snackBar, translate, requestService, logger, router, dialog, layoutUtilsService, featuresPermissionService, userActivityService, idle, swUpdate)
  }

  ngOnInit() {
    // console.log('CustomAppComponent ngOnInit child');
    this.ngOnInitCall();
  }
  public getMe() {
    // console.log('CustomAppComponent getMe child');
    if (!localStorage.getItem('uid')) {
      localStorage.setItem('uid', JSON.stringify(Date.now()));
    }
    if (localStorage.getItem('intg') && localStorage.getItem('i')) {
      let intId = JSON.parse(localStorage.getItem('i'));
      let intg = JSON.parse(localStorage.getItem('intg'));
      this.requestService.intId = intId;
      this.integratedService.setIntegratedProduct(intg);
    }
    if (localStorage.getItem('currentUser') && localStorage.getItem('o') && localStorage.getItem('a') && localStorage.getItem('l')) {
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      let orgId = JSON.parse(localStorage.getItem('o'));
      let appId = JSON.parse(localStorage.getItem('a'));
      let locId = JSON.parse(localStorage.getItem('l'));
      let orgData = JSON.parse(localStorage.getItem('org'));
      this.requestService.orgId = orgId;
      this.requestService.appId = appId;
      this.requestService.locId = locId;
      if (orgData.hasOwnProperty('name')) {
        this.title.setTitle('Stellar - ' + orgData.name);
      }
      this.requestService.pageOrganization.next(orgData);
      this.refreshOrg(orgId);
      this.requestService.currentUser = currentUser;
      let resource = this.requestService.getItemFromListContains(currentUser.resources, orgId, 'organizationId');
      if (resource) {
        this.requestService.updatePermissions(resource);
        this.dataSeenOnce = true;
        this.validateMeByApi();
      }
    } else {
      this.dataSeenOnce = false;
      // this.requestService.logout();
    }
  }
}
