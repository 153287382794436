<form #personForm="ngForm" *ngIf="data" class="customDialog">
	<div class="col-xl-12" *ngIf="data">
		<h1 mat-dialog-title>{{data.title}}</h1>
		<div mat-dialog-content>
			<div *ngIf="data.modalSetting">
				<mat-card *ngIf="data.modalSetting.fields">
					<!-- <mat-card-header>
						<mat-card-title>{{data.type}}</mat-card-title>
					</mat-card-header> -->
					<mat-card-content>
						<div class="form-group">
							<div *ngFor="let col of data.modalSetting.fields" class="fieldItem">
								<div
									*ngIf="col.type !== 'action' && (((col.editable || !col.generated) && col.creatable) || col.control)">
									<!-- <label class="control-label" *ngIf="col.name">{{col.displayName}}<span *ngIf="col.required || !col.generated"> *</span>: </label> -->
									<span class="fieldInput">
										<mat-form-field
											*ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'string'"
											class="inputClass">

											<input matInput [required]="!col.nullable" name="{{col.name}}"
												ng-model="col.name" [(ngModel)]="data.data[col.name]"
												[required]="!col.nullable" placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher" [disabled]="col.disabled">
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} is required.
											</mat-error>
										</mat-form-field>
										<mat-form-field *ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'number'
											&& ((data.dataType !== 'room') || ((data.dataType === 'room' && col.name !== 'order')))" class="inputClass">
											<input *ngIf="col.positive" matInput type="number"
												[required]="!col.nullable" name="{{col.name}}" ng-model="col.name"
												[(ngModel)]="data.data[col.name]" [required]="!col.nullable"
												placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher" min="0">
											<input *ngIf="!col.positive" matInput type="number"
												[required]="!col.nullable" name="{{col.name}}" ng-model="col.name"
												[(ngModel)]="data.data[col.name]" [required]="!col.nullable"
												placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher">
											<mat-icon *ngIf="col.info && col.info !== ''" class="infoCss"
												title="{{col.info | translate}}">info</mat-icon>
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} is required.
											</mat-error>
										</mat-form-field>
										<mat-form-field *ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'number'
											&& (data.dataType === 'room' && col.name === 'order' && data.data['type'] && data.data['type'] === '3')"
											class="inputClass">
											<mat-label>{{col.displayName | translate}}</mat-label>
											<mat-select name="{{col.name}}" [required]="!col.nullable"
												(selectionChange)="setAttribute(col.name, $event.value)"
												[(ngModel)]="data.data[col.name]">
												<!-- <mat-option value="" >Select {{col.displayName | translate}}</mat-option> -->
												<mat-option *ngFor="let itm of orderOfExhibit" [value]="itm">{{itm}}
												</mat-option>
											</mat-select>
											<mat-icon *ngIf="col.info && col.info !== ''" class="infoCss"
												title="{{col.info | translate}}">info</mat-icon>
											<mat-icon *ngIf="data.data[col.name]"
												(click)="clearSimpleValue($event, col.name)" class="cancelIcon"
												[class.cancelIconWithInfo]="col.info && col.info !== ''"
												matTooltip="{{'Clear' | translate}}" matTooltipClass="tooltip-red">close
											</mat-icon>
										</mat-form-field>
										<mat-form-field
											*ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'email'"
											class="inputClass">
											<input matInput type="email" [required]="!col.nullable" name="{{col.name}}"
												ng-model="col.name" [(ngModel)]="data.data[col.name]"
												[required]="!col.nullable" placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher"
												pattern="^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
												[disabled]="data.data._id && col.unique">
											<mat-hint align="end">{{'Email format' | translate}}</mat-hint>
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} is required.
											</mat-error>
										</mat-form-field>
										<mat-form-field
											*ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'url'"
											class="inputClass">
											<input matInput type="text" [required]="!col.nullable" name="{{col.name}}"
												ng-model="col.name" [(ngModel)]="data.data[col.name]"
												[required]="!col.nullable" placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher"
												pattern="^((http[s]?):\/)+\/?([^:\/\s]?)((\/\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$"
												[disabled]="col.disabled || (data.data._id && col.unique)">
											<mat-hint align="end">{{'URL format' | translate}}</mat-hint>
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} is required.
											</mat-error>
										</mat-form-field>
										<mat-form-field
											*ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'phone'"
											class="inputClass">
											<input matInput type="phone" [required]="!col.nullable" name="{{col.name}}"
												ng-model="col.name" [(ngModel)]="data.data[col.name]"
												[required]="!col.nullable" placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher" [disabled]="col.disabled">
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} is required.
											</mat-error>
										</mat-form-field>
										<mat-form-field
											*ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'color'"
											class="inputClass">
											<input matInput type="color" [required]="!col.nullable" name="{{col.name}}"
												ng-model="col.name" [(ngModel)]="data.data[col.name]"
												[required]="!col.nullable" placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher" [disabled]="col.disabled">
											<button mat-icon-button matSuffix
												*ngIf="data.data[col.name] && data.data[col.name] !== '' && !col.disabled"
												(click)="data.data[col.name] = ''">
												<mat-icon style="font-size: 30px;">format_color_reset</mat-icon>
											</button>
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} is required.
											</mat-error>
										</mat-form-field>
										<mat-form-field *ngIf="col.type === 'password'" class="inputClass">
											<input matInput type="password" maxlength="64" minlength="8"
												[required]="!col.nullable" name="{{col.name}}" ng-model="col.name"
												[(ngModel)]="data.data[col.name]" [required]="!col.nullable"
												placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher" [disabled]="col.disabled">
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} is required.
											</mat-error>
											<mat-error
												*ngIf="data.data[col.name] && (data.data[col.name].length < 8 || data.data[col.name].length > 64)">
												{{col['displayName'] | translate}} should be of at least 8 characters
												and maximum 64 characters.
											</mat-error>
										</mat-form-field>
										<div
											*ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'textarea'">
											<mat-form-field
												hintLabel="Max {{col.charlimit ? col.charlimit : 1000}} characters">
												<textarea matInput placeholder="{{col['displayName'] | translate}}"
													name="{{col['name']}}" [required]="!col['nullable']"
													maxlength="{{col.charlimit ? col.charlimit : 1000}}"
													[(ngModel)]="data.data[col.name]" ng-model="col['name']"
													cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"
													[errorStateMatcher]="esMatcher" [disabled]="col.disabled">
													</textarea>
												<mat-hint align="end">
													{{data.data[col.name]?.length || 0}}/{{col.charlimit ? col.charlimit
													: 1000}}
													<mat-icon *ngIf="col.info && col.info !== ''" class="infoCss"
														title="{{col.info | translate}}">info</mat-icon>
												</mat-hint>
												<mat-error
													*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
													{{col['displayName'] | translate}} is required.
												</mat-error>
											</mat-form-field>
											<!-- <mat-form-field hintLabel="Max 10 characters" appearance="fill">
    <mat-label>Enter some input</mat-label>
    <input matInput #input maxlength="10" placeholder="Ex. Nougat">
    <mat-hint align="end">{{input.value?.length || 0}}/10</mat-hint>
  </mat-form-field> -->
										</div>
										<div *ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'boolean' && (col.name !== 'privacyterm' && col.name !== 'isSuperAdmin')"
											style="margin-bottom:15px;">
											<mat-checkbox class="example-margin" name="{{col.name}}"
												[(ngModel)]="data.data[col.name]"
												[disabled]="col.disabled || (col.name === 'isPasswordRequired' && data.data['isSuperAdmin']) || (data.action && data.action === 'create-room-lobby' && (col.name === 'main' || col.name === 'emptyRoom' || col.name === 'showbg')) || (data.dataType === 'session' && data.data['isKeepActive'] && col.name === 'autostart') || (data.dataType === 'session' && (data.data['isKeepActive'] || data.data['streamMode'] === 'hls') && col.name === 'autoend' ) || (data.dataType === 'session' && data.data['streamMode'] === 'hls' && col.name === 'isKeepActive')"
												(change)="setBooleanAttribute(col.name, $event.checked)"
												title="{{col.info | translate}}">{{col.displayName}} <mat-icon
													*ngIf="col.info && col.info !== ''" class="infoCss"
													title="{{col.info | translate}}">info</mat-icon>
											</mat-checkbox>
											<mat-icon *ngIf="col.name === 'isRecurrence' && data.data['isRecurrence']"
												class="recurrenceCss" title="{{'Edit Recurrency' | translate}}"
												(click)="editRecurrence()">event_repeat</mat-icon>
											<!-- <mat-radio-group [value]="data.data[col.name]" name="{{col.name}}"  (change)="setAttributeDummy(index, 'value', $event.value)">
									        <mat-radio-button class="example-margin" value="false">False</mat-radio-button>
									        <mat-radio-button class="example-margin" value="true">True</mat-radio-button>
									      </mat-radio-group> -->
										</div>
										<div *ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'boolean' && col.name === 'isSuperAdmin' && (data.data['resources'] && data.data['resources'].length > 0 && data.data['resources'][0]['_id'] === adminId)"
											style="margin-bottom:15px;">
											<mat-checkbox class="example-margin" name="{{col.name}}"
												[(ngModel)]="data.data[col.name]"
												(change)="setSuperAdminAttribute( $event.checked)"
												title="{{col.info | translate}}" [disabled]="col.disabled">
												{{col.displayName}} <mat-icon *ngIf="col.info && col.info !== ''"
													class="infoCss" title="{{col.info | translate}}">info</mat-icon>
											</mat-checkbox>
											<!-- <mat-radio-group [value]="data.data[col.name]" name="{{col.name}}"  (change)="setAttributeDummy(index, 'value', $event.value)">
									        <mat-radio-button class="example-margin" value="false">False</mat-radio-button>
									        <mat-radio-button class="example-margin" value="true">True</mat-radio-button>
									      </mat-radio-group> -->
										</div>
										<div
											*ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'tags'">
											<app-custom-tags #customTag [parentFormSubmitted]="isSubmitted"
												[value]="data.data[col.name]" [required]="!col.nullable"
												[placeholder]="col.displayName" [canAdd]="true"
												(onSelectReturn)="setAttribute(col.name, $event)" [itemName]="col.name"
												[canDelete]="true" [dataTypeParent]="data.dataType"
												[tagType]="col.tagtype" [dataTypeParentDisplay]="data.dataType">
											</app-custom-tags>
										</div>
										<div
											*ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'reference'">
											<app-custom-select *ngIf="col.control && col.reference.kind === 'single'"
												[parentFormSubmitted]="isSubmitted" [value]="data.data[col.name]['_id']"
												[itemName]="col.name" [required]="!col.nullable"
												[dataType]="col.reference.to" [apiTarget]="col.reference.api"
												[filters]="col.filters" [placeholder]="col.displayName"
												(onSelectReturn)="setReferenceControllerAttribute(col.name, '_id', $event)"
												[disabled]="col.disabled"></app-custom-select>
											<app-custom-select
												*ngIf="!col.control && data.data[col.name] && col.reference.kind === 'single'"
												[parentFormSubmitted]="isSubmitted" [value]="data.data[col.name]['_id']"
												[itemName]="col.name" [required]="!col.nullable"
												[dataType]="col.reference.to" [filters]="col.filters"
												[apiTarget]="col.reference.api" [placeholder]="col.displayName"
												(onRoleSelectReturn)="setRoleReferenceAttribute(col.name, $event)"
												(onSelectReturn)="setReferenceAttribute(col.name, '_id', $event)"
												[disabled]="col.disabled"></app-custom-select>
											<app-custom-select
												*ngIf="data.data[col.name] && col.reference.kind === 'multiple' && col.name === 'resources'"
												[parentFormSubmitted]="isSubmitted"
												[value]="data.data[col.name][0]['_id']" [itemName]="col.name"
												[required]="!col.nullable" [dataType]="col.reference.to"
												[apiTarget]="col.reference.api" [placeholder]="col.displayName"
												(onRoleSelectReturn)="setRoleReferenceAttribute(col.name, $event)"
												[filters]="col.filters"
												(onSelectReturn)="setReferenceAttribute(col.name, '_id', $event)"
												[disabled]="col.disabled"></app-custom-select>
											<app-custom-select
												*ngIf="data.data[col.name] && col.reference.kind === 'multiple' && col.name === 'role'"
												[parentFormSubmitted]="isSubmitted"
												[value]="data.data[col.name][0]['_id']" [itemName]="col.name"
												[required]="!col.nullable" [dataType]="col.reference.to"
												[apiTarget]="col.reference.api" [placeholder]="col.displayName"
												(onRoleSelectReturn)="setRoleAttribute(col.name, $event)"
												[filters]="col.filters"
												(onSelectReturn)="setReferenceAttribute(col.name, '_id', $event)"
												[disabled]="col.disabled"></app-custom-select>
											<app-custom-multiple-select
												*ngIf="data.data[col.name] && col.reference.kind === 'multiple' && col.name !== 'resources' && col.name !== 'role'"
												[value]="data.data[col.name]" [itemName]="col.name"
												[required]="!col.nullable" [dataType]="col.reference.to"
												[apiTarget]="col.reference.api" [placeholder]="col.displayName"
												(onSelectReturn)="setMultipleReferenceAttribute(col.name, $event)">
											</app-custom-multiple-select>
										</div>
										<div *ngIf="data.dataType === 'schedule/event' &&  (col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'objectjson' && col.name === 'attendees'"
											style="display: inline-block;" style="width:100%">
											<app-event-involved-object [parentFormSubmitted]="isSubmitted"
												[value]="data.data['attendees']" [required]="!col.nullable"
												[placeholder]="metaFieldSetting['attendees'].displayName"
												[canAdd]="true" (onSelectReturn)="setAttribute(col.name, $event)"
												[type]="['user', 'role']" [canSelectRole]="true">
											</app-event-involved-object>
										</div>
										<div *ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'picturearray'"
											class="picturearray" style="display: inline-block;">
											<label class="control-label" *ngIf="col.name">{{col.displayName}}<span
													*ngIf="!col.nullable"> *</span>:
												<mat-icon (click)="fileselect.click()" class="newRow" title="Add Image">
													add_circle_outline</mat-icon>
											</label>
											<input [hidden]="true" type="file" name="image" #fileselect multiple="true"
												accept="{{allowedAcceptExtensions}}"
												(change)="onBrowseFiles(col.name, $event.target)" />
											<div *ngIf="data.data[col.name]">
												<mat-card class="itemView"
													*ngFor="let itm of data.data[col.name]; let index=index">
													<mat-card-header>
														<div style="text-align: right;width: 100%;z-index: 1;">
															<em class="material-icons" style="cursor: pointer;"
																(click)="deletePictures(col.name, index)">delete_forever</em>
														</div>
													</mat-card-header>
													<div class="imageParent">
														<img mat-card-image src="{{itm.thumbnail}}"
															(error)="itm.thumbnail = itm.url"
															style="max-height: 190px;max-width: 180px;">
													</div>
													<mat-card-content class="itemData">
														<label class="control-label">Link To:</label>
														<mat-select ng-model="col.name"
															(selectionChange)="setPictureArrayType(index, col.name, $event.value)"
															[value]="data.data[col.name][index]['type']">
															<mat-option value="none">{{'None' | translate}}</mat-option>
															<mat-option value="link">{{'Url' | translate}}</mat-option>
															<mat-option value="tile">{{'Tile' | translate}}</mat-option>
															<mat-option value="room">{{'Room' | translate}}</mat-option>
															<mat-option value="page">{{'Page' | translate}}</mat-option>
														</mat-select>
														<!-- <mat-form-field class="example-full-width" *ngIf="data.data[col.name][index]['type'] !== 'none'">
														    <input name="link{{index}}" matInput ng-model="col.name"
																 [readonly]="data.data[col.name][index]['type'] !== 'link' && data.data[col.name][index]['type'] !== 'page'" [(ngModel)]="data.data[col.name][index]['link']">
														    <mat-icon matSuffix *ngIf="data.data[col.name][index]['type'] !== 'link' && data.data[col.name][index]['type'] !== 'page'" style="cursor:pointer" (click)="selectPictureLinkType(index, col.name, data.data[col.name][index]['type'])">mode_edit</mat-icon>
														  </mat-form-field> -->

														<mat-form-field type="url" style="width: 100%;"
															*ngIf="data.data[col.name][index]['type'] === 'link'">
															<input name="link{{index}}" matInput
																placeholder="{{ 'Link' | translate }}*"
																[(ngModel)]="data.data[col.name][index]['link']">
														</mat-form-field>
														<mat-form-field type="text" style="width: 100%;"
															*ngIf="data.data[col.name][index]['type'] === 'room' || data.data[col.name][index]['type'] === 'tile'">
															<input name="link{{index}}" matInput [readonly]="true"
																[(ngModel)]="data.data[col.name][index]['linkDisplay']"
																placeholder="{{ 'Select' | translate  }} {{data.data[col.name][index]['type']}}*">
															<mat-icon matSuffix style="cursor:pointer"
																(click)="selectPictureLinkType(index, col.name, data.data[col.name][index]['type'])">
																mode_edit</mat-icon>
														</mat-form-field>
														<mat-form-field class="example-full-width"
															*ngIf="data.data[col.name][index]['type'] === 'page'">
															<mat-label>{{'Select Page' | translate}}</mat-label>
															<mat-select name="link{{index}}"
																(selectionChange)="data.data[col.name][index]['link'] = $event.value"
																[value]="data.data[col.name][index]['link']">
																<ng-container *ngFor="let pg of pageList">
																	<mat-option *ngIf="!checkDisabled(pg.value)"
																		value="{{pg.value}}">{{pg.name | translate}}
																	</mat-option>
																</ng-container>
															</mat-select>
														</mat-form-field>


													</mat-card-content>
												</mat-card>
											</div>
										</div>
										<div
											*ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'datetime'">
											<app-custom-datetime [value]="data.data[col.name]" [metadata]="col"
												(dateChange)="setDateTimeAttribute(col.name, $event)"
												[disabled]="col.disabled"
												[disabledDate]="col.disabled || col.disabledDate"
												[disabledTime]="col.disabled || col.disabledTime">
											</app-custom-datetime>
										</div>
										<div
											*ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'date'">
											<mat-form-field>
												<input matInput name="{{col.name}}" [required]="!col.nullable"
													[(ngModel)]="data.data[col.name]" ng-model="col.name"
													[matDatepicker]="picker"
													placeholder="{{col.displayName| translate}}"
													(dateChange)="setDateAttribute(col.name, $event.value)"
													[errorStateMatcher]="esMatcher">
												<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
												<mat-datepicker #picker></mat-datepicker>
												<mat-error
													*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
													{{col.displayName| translate}} is required.
												</mat-error>
											</mat-form-field>
											<mat-form-field
												*ngIf="col.name == 'dob' && data.dataType === 'resource/user' && (metaFieldSetting['age'].visible || (metaFieldSetting['age'].admin && isAdmin) || (metaFieldSetting['age'].superadmin && isSuperAdmin))"
												class="inputClass">
												<input matInput type="number" [readonly]="true" name="age"
													ng-model="'age'" [value]="age"
													placeholder="{{metaFieldSetting['age']['displayName'] | translate}}">
												<mat-icon
													*ngIf="metaFieldSetting['age'].info && metaFieldSetting['age'].info !== ''"
													class="infoCss"
													title="{{metaFieldSetting['age'].info | translate}}">info</mat-icon>
											</mat-form-field>
										</div>
										<ng-container
											*ngIf="(col.visible || (col.admin && isAdmin) || (col.superadmin && isSuperAdmin)) && col.type === 'enum'">
											<ng-container
												*ngIf="!col.multiple && (((data.dataType !== 'room') || ((data.dataType === 'room' && col.name !== 'priority' && col.name !== 'type') || (data.dataType === 'room' && col.name === 'priority' && data.data['type'] && data.data['type'] === '3'))))">
												<mat-form-field class="enumClass">
													<input [style.visibility]="'hidden'" matInput name="{{col.name}}"
														[(ngModel)]="data.data[col.name]" [required]="!col.nullable"
														[errorStateMatcher]="esMatcher">
													<mat-label>{{col.displayName | translate}}</mat-label>
													<mat-select name="{{col.name}}" [required]="!col.nullable"
														(selectionChange)="setAttribute(col.name, $event.value)"
														[(ngModel)]="data.data[col.name]" [disabled]="col.disabled">
														<!-- <mat-option value="" >Select {{col.displayName | translate}}</mat-option> -->
														<mat-option *ngFor="let itm of col.enum" [value]="itm.value"
															[disabled]="col.enumDisabled && col.enumDisabled.includes(itm.value)">
															{{itm.displayName | translate}}</mat-option>
													</mat-select>
													<!-- <button mat-button *ngIf="data.data[col.name]" class="cancelBIcon" matSuffix mat-icon-button aria-label="Clear" (click)="clearSimpleValue($event, col.name)">
													<mat-icon>close</mat-icon>
												  </button> -->
													<mat-icon *ngIf="col.info && col.info !== ''" class="infoCss"
														title="{{col.info | translate}}">info</mat-icon>
													<mat-icon *ngIf="data.data[col.name] && !col.disabled"
														(click)="clearSimpleValue($event, col.name)" class="cancelIcon"
														[class.cancelIconWithInfo]="col.info && col.info !== ''"
														matTooltip="{{'Clear' | translate}}"
														matTooltipClass="tooltip-red">close</mat-icon>
													<mat-error
														*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
														{{col.displayName | translate}} is required.
													</mat-error>
												</mat-form-field>
											</ng-container>
											<ng-container
												*ngIf="!col.multiple && (data.dataType === 'room' && col.name === 'type' && (!data.action || (data.action && (data.action === 'create' || data.action === 'edit'))) && data.data[col.name] !== '3')">
												<mat-form-field>
													<input [style.visibility]="'hidden'" matInput name="{{col.name}}"
														[(ngModel)]="data.data[col.name]" [required]="!col.nullable"
														[errorStateMatcher]="esMatcher">
													<mat-label>{{col.displayName | translate}}</mat-label>
													<mat-select name="{{col.name}}" [required]="!col.nullable"
														(selectionChange)="setAttribute(col.name, $event.value)"
														[(ngModel)]="data.data[col.name]" [disabled]="col.disabled">
														<mat-option value="">Select {{col.displayName | translate}}
														</mat-option>
														<ng-container *ngFor="let itm of col.enum">
															<mat-option *ngIf="itm.value !== '3'" [value]="itm.value">
																{{itm.displayName | translate}}</mat-option>
														</ng-container>
													</mat-select>
													<mat-error
														*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
														{{col.displayName | translate}} is required.
													</mat-error>
												</mat-form-field>
											</ng-container>
											<ng-container *ngIf="col.multiple">
												<mat-form-field>
													<input [style.visibility]="'hidden'" matInput name="{{col.name}}"
														[(ngModel)]="data.data[col.name]" [required]="!col.nullable"
														[errorStateMatcher]="esMatcher">
													<mat-label>{{col.displayName | translate}}</mat-label>
													<mat-select name="{{col.name}}" [required]="!col.nullable"
														(selectionChange)="setAttribute(col.name, $event.value)"
														[(ngModel)]="data.data[col.name]" [disabled]="col.disabled"
														multiple>
														<ng-container *ngFor="let itm of col.enum">
															<mat-option [value]="itm.value"
																[disabled]="col.enumDisabled && col.enumDisabled.includes(itm.value)">
																{{itm.displayName |
																translate}}</mat-option>
														</ng-container>
													</mat-select>
													<mat-error
														*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
														{{col.displayName | translate}} is required.
													</mat-error>
												</mat-form-field>
												<div *ngIf="data.dataType === 'schedule/event' && col.name === 'eventCreatorType' && !col.disabled && (data.data[col.name].includes('user') || data.data[col.name].includes('role'))"
													class="attendance">
													<app-event-involved-object [parentFormSubmitted]="isSubmitted"
														[value]="data.data['eventCreator']" [required]="true"
														[placeholder]="metaFieldSetting['eventCreator'].displayName"
														[canAdd]="true"
														(onSelectReturn)="setAttribute('eventCreator', $event)"
														[type]="data.data[col.name]"></app-event-involved-object>
												</div>
											</ng-container>
										</ng-container>

									</span>
								</div>
							</div>
						</div>
					</mat-card-content>
				</mat-card>
			</div>
		</div>
		<div mat-dialog-actions>
			<!-- <span *ngIf="data.modalSetting && !data.modalSetting.hasOwnProperty('footer')"> -->
			<button color="danger" [disabled]="(data.action && data.action === 'create-room-lobby')" mat-raised-button
				(click)="closeModal(undefined)">{{'Close' | translate}}</button>
			<button *ngIf="!data.data._id" mat-raised-button (click)="saveData('create')"
				[disabled]="loading">{{'Create' | translate}}</button>
			<button *ngIf="data.data._id" mat-raised-button (click)="saveData('edit')" [disabled]="loading">{{'Save' |
				translate}}</button>
			<mat-checkbox *ngIf="!data.data._id && data.dataType === 'resource/user'" class="sendEmail" name="sendEmail"
				[(ngModel)]="sendEmail" title="{{'Send registration email' | translate}}">
				{{'Send registration email' | translate}}
			</mat-checkbox>
		</div>
		<br />
	</div>
</form>