import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { StoreService } from 'src/app/shared/services/store.service';
import { UserActivityService } from 'src/app/shared/services/userActivity.service';
import { Utils } from 'src/app/shared/helpers/utils';
import { NgxPermissionsService, NgxRolesService } from 'src/app/shared/modules/ngx-permissions';
import { FeaturesPermissionService } from 'src/app/shared/services/featuresPermission.service';
import { RequestService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { ContentType } from 'src/app/shared/services/enums';
import { Dictionary } from 'src/app/shared/services/interface';

@Injectable()
export class CustomRequestService extends RequestService {

  public intId = undefined;
  constructor(protected utils: Utils, public store: StoreService, protected router: Router, protected http: HttpClient,
    protected permissionsService: NgxPermissionsService, protected userActivityService: UserActivityService, protected featuresPermissionService: FeaturesPermissionService
  ) {
    super(utils, store, router, http, permissionsService, userActivityService, featuresPermissionService);

    console.log('CustomRequestService constructor child');
  }
  public logout(redirect = true, showDialog = false, notExist = false, noURLReturn = false) {
    console.log('CustomRequestService logout child');
    this.userActivityService.insertLog('logout', 'logout', 'RequestService', { redirect, showDialog });
    localStorage.removeItem('currentUser');
    localStorage.removeItem('i');
    localStorage.removeItem('intg');
    localStorage.removeItem('o');
    localStorage.removeItem('org');
    localStorage.removeItem('a');
    localStorage.removeItem('l');
    localStorage.removeItem('isOwner');
    sessionStorage.removeItem('live');
    // this.logOutApi();// don't enable this, cause a signal is being sent by backend
    // sessionStorage.clear()
    this.appStatusSubject.next(undefined);
    this.authenticatedUser.next(false);
    this.currentUser = undefined;
    if (redirect) {
      if (sessionStorage.getItem('loginEnteredIds')) {
        let loginEnteredIds = JSON.parse(sessionStorage.getItem('loginEnteredIds'));
        let orgId = loginEnteredIds.orgId;
        if (loginEnteredIds.hasOwnProperty('appId') && loginEnteredIds.hasOwnProperty('locId') && loginEnteredIds.hasOwnProperty('lang')) {
          let appId = loginEnteredIds.appId;
          let locId = loginEnteredIds.locId;
          let lang = loginEnteredIds.lang;
          if (showDialog) {
            this.sendPostMessage('stellar:logout');
            this.router.navigate(['/loginWithDialog', orgId, appId, locId, lang]);
          } else if (notExist) {
            this.sendPostMessage('stellar:logout');
            this.router.navigate(['/login', orgId, appId, locId, lang]);
          } else {
            if (noURLReturn) {
              this.sendPostMessage('stellar:logout');
              this.router.navigate(['/login', orgId, appId, locId, lang]).then(() => {
                window.location.reload();
              });
            } else {
              window.location.reload();
            }
          }
        } else {
          if (loginEnteredIds.hasOwnProperty('intId')) { // added for integrated product
            orgId = loginEnteredIds.intId;
          }
          if (showDialog) {
            this.sendPostMessage('stellar:logout');
            this.router.navigate(['/loginWithDialog', orgId]);
          } else if (notExist) {
            this.sendPostMessage('stellar:logout');
            this.router.navigate(['/login', orgId]);
          } else {
            if (noURLReturn) {
              this.sendPostMessage('stellar:logout');
              this.router.navigate(['/login', orgId]).then(() => {
                window.location.reload();
              });
            } else {
              window.location.reload();
            }
          }
        }
      } else {
        if (showDialog) {
          this.sendPostMessage('stellar:logout');
          this.router.navigate(['/loginWithDialog']);
        } else if (notExist) {
          this.sendPostMessage('stellar:logout');
          this.router.navigate(['/login']);
        } else {
          if (noURLReturn) {
            this.sendPostMessage('stellar:logout');
            this.router.navigate(['/login']).then(() => {
              window.location.reload();
            });
          } else {
            window.location.reload();
          }
        }
      }
    }
  }
  public getIntegratedData(id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, cached: boolean = false, lang?: string) {
    if (cached) {
      if (this.cachedObj.hasOwnProperty('integrated/product/' + id)) {
        callback(this.cachedObj['integrated/product/' + id], undefined);
        return;
      }
    }
    let urlStr = this.authCASURL + 'integrated/product/' + id;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          // if (cached) {
          this.cachedObj['integrated/product/' + id] = jsonObj.results;
          // }
          callback(jsonObj.results, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getIntgUserMetaData(type: string, feilds: any[], callback: (dataResponse: any | undefined, requestError: any | undefined) => void, orgId?: string, lang?: string) {
    let urlStr = this.authCASURL + type + '/integrated/metadata';
    let ic = '?';
    if (feilds) {
      urlStr = urlStr + ic + 'fields=' + feilds;
      ic = '&';
    }
    if (orgId) {
      urlStr = urlStr + ic + 'organizationId=' + orgId;
      ic = '&';
    }
    if (lang) {
      urlStr = urlStr + ic + 'language=' + lang;
    }
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public saveIntgUserData(data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    if (data.hasOwnProperty('_id') && data._id) {
      let urlStr = this.authCASURL + 'user/' + data._id + '/update';
      return this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, ' Connectivity issue.');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);
          } else {
            if (jsonObj.hasOwnProperty('type')) {
              this.appStatusSubject.next(jsonObj.type);
            }
            callback(undefined, jsonObj.message);
          }
        } else {
          callback(undefined, error);
        }
      }, 'POST', data, ContentType.JSON, 10.0, false, 1.5, 60.0, false, false, true);
    } else {
      let urlStr = this.authCASURL + 'user/create';
      // urlStr = this.addLanguageToURL(urlStr, lang);
      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, ' Connectivity issue.');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);
          } else {
            if (jsonObj.hasOwnProperty('type')) {
              this.appStatusSubject.next(jsonObj.type);
            }
            callback(undefined, jsonObj.message);
          }
        } else {
          callback(undefined, error);
        }
      }, 'POST', data, ContentType.JSON, 10.0, false, 1.5, 60.0, false, false, true);
    }
  }
  public getIntSingleUserData(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authCASURL + type + '/' + id + '/get';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, undefined, true);
  }
  protected jsonGetRequest(urlString: string, callback: (json?: any, error?: any) => void, params?: Dictionary, includeToken: boolean = false) {
    if (urlString) {
      let urlComps = urlString;
      if (params) {
        for (let urlItem of Object.keys(params)) {
          urlComps += '&' + urlItem + '=' + params[urlItem];
        }
      }
      return this.jsonRequest(urlComps, callback, 'GET', undefined, ContentType.JSON, 10.0, false, 1.5, 60.0, false, false, includeToken);
    } else {
      return;
    }
  }
  protected jsonRequest(urlString: string,
    callback: (json: any, error: any) => void,
    method: string = 'POST',
    postBody: any = undefined,
    contentType: string = ContentType.JSON,
    timeout: number = 10.0,
    retry: boolean = false,
    retryFactor: number = 1.5,
    maxTimeout: number = 60.0, excludeVertical: boolean = false, excludeProject: boolean = false, includeToken: boolean = false) {
    if (urlString) {
      let url: string = urlString || '';
      let headers = {
        'Accept': 'application/json',
      };
      if (contentType)
        headers['Content-Type'] = contentType;
      if (this.token && (urlString.startsWith(this.authURL) || urlString.startsWith(environment.trackingUrl) || includeToken)) {
        headers['Authorization'] = this.token;
      }
      if (!excludeVertical)
        headers['vertical'] = this.orgType;
      if (!excludeProject)
        headers['project'] = 'stellar';

      headers['productid'] = environment.productId;
      if (this.orgId && this.orgId !== '') {
        headers['organizationid'] = this.orgId;
        headers['integratedid'] = this.orgId;
      }
      if (this.intId && this.intId !== '') {
        headers['integratedid'] = this.intId;
      }
      const httpHeaders = new HttpHeaders(headers);
      let httpOptions: any = {
        responseType: 'json',
        headers: httpHeaders,
        method: method
      }

      let bodyString = postBody;
      if (method === 'POST') {
        bodyString = JSON.stringify(postBody);
        httpOptions['body'] = bodyString;
      }
      return this.http.request(method, url, httpOptions)
        // .pipe(map(
        //     (res: any) => {
        //       // below might need to be changed
        //       if (res.status >= 404) {
        //         window.location.reload();
        //       } else if (res.status >= 400) {
        //         callback(undefined, 'server');
        //         return;
        //       }
        //       return res;
        //     }
        //   ))
        .subscribe(
          (data) => {
            let version = undefined;
            let showdialog = undefined;
            if (data && data.hasOwnProperty('version') && data.hasOwnProperty('showdialog')) {
              version = data['version'];
              showdialog = data['showdialog'];
              this.setVersion(version, showdialog);
            }
            // this.userActivityService.insertLog('api', 'jsonRequest', 'Api return Successfully', {version, showdialog}, url);
            if (data.hasOwnProperty('status')) {
              if (!data['status']) {
                this.userActivityService.insertLog('api', 'jsonRequest', 'Api return Successfully but status false', { data, showdialog, headers }, url);
              } else {
                this.userActivityService.insertLog('api', 'jsonRequest', 'Api return Successfully', { version, showdialog, headers }, url);
              }
            }
            callback(data, undefined);
            // console.log(url, data);
          },
          (err) => {
            this.userActivityService.insertLog('api', 'jsonRequest', 'Api Return Unsuccessfully', { err, headers, httpOptions }, url);
            if (err) {
              if (err.status >= 404) {
                // window.location.reload();
                callback(undefined, 'Refresh page');
              } else if (err.status >= 400) {
                if (this.currentUser) {
                  try {
                    let jsonErr = err.json();
                    if (jsonErr.hasOwnProperty('type') && jsonErr.type === 'login') {
                      this.appStatusSubject.next(jsonErr.type);
                      // this.logout();
                    } else {
                      callback(undefined, ' Connectivity issue.');
                    }
                  } catch (e1) {
                    try {
                      if (err.hasOwnProperty('error')) {
                        let jsonErr = err.error;
                        if (jsonErr.hasOwnProperty('type') && jsonErr.type === 'login') {
                          this.appStatusSubject.next(jsonErr.type);
                          // this.logout();
                        } else {
                          callback(undefined, ' Connectivity issue.');
                        }
                      } else {
                        callback(undefined, ' Connectivity issue.');
                      }
                    } catch (e2) {
                      callback(undefined, ' Connectivity issue.');
                    }
                  }
                }
              } else {
                callback(undefined, ' Connectivity issue.');
              }
            }
          });

    } else {
      // this.logger.log('Failed to create URL');
      //console.log('Failed to create URL');
    }
  }
}
